import { Component, OnInit, ViewChild, Input, Optional, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { PrdctnService } from '../prdctn/prdctn.service';
import { FormGroup, FormControl, Validators, RequiredValidator, FormBuilder, FormArray } from '@angular/forms';
import { NotificationService } from 'src/app/Services/Common/notification.service';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ProductHelpComponent } from '../../shared/product-help/product-help.component';
import { Sort, MatDialog, MatTableDataSource, MatSnackBar } from '@angular/material';
import { PrdctnListComponent } from '../prdctn/prdctn-list/prdctn-list.component';
import { BOMComponent } from '../bom/bom.component';
import { ManufacturingService } from '../manufacturing.services';
import * as moment from 'moment'

@Component({
  selector: 'app-prdctn-crud',
  templateUrl: './prdctn-crud.component.html',
  styleUrls: ['./prdctn-crud.component.css']
})
export class PrdctnCrudComponent implements OnInit, OnDestroy {

  loading: boolean = false;
  DataForm: FormGroup;
  HelpForm: FormGroup;
  title: string;
  initialValues: any[];
  repmodel: any = {};
  Dept_nm: any = [];
  productlist: any = [];
  @Input() Unit: string;
  filteredOptions: any = [];
  HelpList: any = [];
  @ViewChild(ProductHelpComponent, { static: false }) child1: ProductHelpComponent;
  msgFromChild1: any;
  Product_id = '';
  Product_nm = '';
  isDisabled = true;
  datalist: any = [];
  OrdData: any = [];
  msgFromBOM1: any;
  no_of_msg: number = 0;
  prderrmsg: string;
  now = new Date();
  year = this.now.getFullYear();
  month = this.now.getMonth();
  day = this.now.getDay();
  index1: number = 0;
  minDate = moment(new Date(this.hardfunc.Fdate)).format("YYYY-MM-DD");
  maxDate = this.hardfunc.Tdate;

  constructor(private PrdctnService: PrdctnService,
              private fb: FormBuilder,
              private notificationService: NotificationService,
              private hardfunc: HardFuncService,
              @Optional() @Inject(MAT_DIALOG_DATA) public ReceivedData: any,
              @Optional() public dialogRef: MatDialogRef<PrdctnListComponent>,
              private _http: HttpClient,
              private dialog: MatDialog,
              private Manufacturing: ManufacturingService,) { }

  ngOnInit() {    
    this.DataForm = this.createFormGroup();
    this.initialValues = this.DataForm.value;
    this.HelpForm = this.HelpFormGroup();
    this.loading = true;
    this.GetData();
    this.getDeptName();
    this.getProductlist(0);
  }
  ngOnDestroy(): void {
    console.clear();
  }
  GetData() {
    if (this.ReceivedData) {

      if (this.ReceivedData.id == -1) {
        this.title = "ADDITION";
      }
      else {
        this.PrdctnService.GetDatabyId(this.ReceivedData.id, this.ReceivedData.TranType)
          .subscribe(
            (data: any) => {
              this.DataForm.patchValue(data);
              const formarr = new FormArray([]);
              data.Items.map(m => {
                formarr.push(
                  this.fb.group({
                    Ow_mast_id: m.Ow_mast_id,
                    Product_id: m.Product_id,
                    Product_nm: m.Product_nm,
                    Qnty: m.Qnty,
                    Qnty2: m.Qnty2,
                    Unit: m.Unit,
                  })
                );
              });
              this.index1++;
              console.log(this.index1);
              this.DataForm.setControl("Items", formarr);

              // let index: number = 0;
              this.CheckBOM(0);
              this.index1 = 0;
              let formarray = (this.DataForm.controls['Items'] as FormArray);
              let formgroup = (formarray.at(this.index1) as FormGroup);

              data.Items.map(m => {
                console.log(m);
                this.Manufacturing.GetBomItems(m.Product_id)
                  .subscribe(
                    (data1: any) => {
                      console.log(data1);
                      const fa = new FormArray([]);
                      data1.map(m => {
                        console.log(m);
                        let data = this.fb.group({
                          Product_id: m.Product_id,
                          Product_nm: m.Short_nm,
                          Unit: m.Unit,
                          Bom_qnt: m.Qnt,
                          Stock: m.Stock || 0
                        })
                        fa.push(data);
                      })
                    });
              })
              this.title = "EDITING";
              return false;
            },
            err => {
              let errstr = this.hardfunc.getError(err);
              console.log(err);
              this.notificationService.warn(errstr);
            }
          )
      }
    }
    else {
      this.ReceivedData = {};
      this.ReceivedData.id = -1;
      this.title = "ADDITION";
    }
  }

  SaveTrans() {
    let Tdate = this.hardfunc.hsConvDt(this.DataForm.controls.Tdate.value);
    this.DataForm.controls.Tdate.patchValue(Tdate);
    console.log(this.DataForm.value);
    this.PrdctnService.SaveData(this.DataForm.value)
      .subscribe(
        data => {
          this.loading = false;
          this.ResetData();
          // this.GetData();
          this.notificationService.success(' Data Saved Successfully... ');
        },
        err => {
          let errstr = this.hardfunc.getError(err);
          console.log(err);
          this.notificationService.warn(errstr);
          this.loading = false;
        })
  }

  SaveHelp() {
  }
  //For Help Dialog
  HelpDialog(index: number) {
    const dialogRef = this.dialog.open(ProductHelpComponent, {
      width: '970px',
      height: '848px',
      data: { i: index }
    });
    dialogRef.afterClosed().subscribe(result => {
      let formarray = (this.DataForm.controls['Items'] as FormArray);
      let formgroup = (formarray.at(index) as FormGroup);
      formgroup.get('Product_id').patchValue(result.Product_id);
      formgroup.get('Product_nm').patchValue(result.Short_nm.trim());
      formgroup.get('Unit').patchValue(result.Unit);
      console.log('The dialog was closed');
    });
  }

  OpenBOM(index: number) {
    let formarray = (this.DataForm.controls['Items'] as FormArray);
    let formgroup = (formarray.at(index) as FormGroup);
    const dialogRef = this.dialog.open(BOMComponent, {
      width: '970px',
      height: '848px',
      data: {
        i: index, Product_id: formgroup.get('Product_id').value, //this.DataForm.get("Store_id").value
        Product_nm: formgroup.get('Product_nm').value,
        Qnty: formgroup.get('Qnty').value
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  CheckBOM(index: number) {
    this.loading = true;
    this.datalist = [];
    let Issue_to = this.DataForm.controls.Issue_to.value;
    let formarray = (this.DataForm.controls['Items'] as FormArray);
    let formgroup = (formarray.at(index) as FormGroup);
    if (this.ReceivedData) {
      this.Manufacturing.GetBomStock(formgroup.get('Product_id').value,
        formgroup.get('Product_nm').value, Issue_to)
        .subscribe(
          (data1: any) => {    
            console.log(data1);
            const fa = new FormArray([]);
            data1.map(m => {              
              let data = this.fb.group({
                Product_id: m.Product_id,
                Product_nm: m.Short_nm,
                Unit: m.Unit,
                Bom_qnt: m.Qnt,
                Stock: m.Stock || 0
              })
              fa.push(data);
            })
            formgroup.setControl("BomItems", fa);
          },
          err => {
            let errstr = this.hardfunc.getError(err);
            this.notificationService.warn(errstr);
          }
        )
    }
  }
  ResetData() {
    if (this.ReceivedData.id > 0) {
      this.dialogRef.close();
    }
    else {
      this.DataForm.reset();
      this.DataForm.patchValue(this.initialValues);
      Object.keys(this.DataForm.controls).forEach(key => {
        this.DataForm.get(key).setErrors(null);
      });
    }
  }

  addNext() {
    (this.DataForm.controls['Items'] as FormArray).push(this.createItem())
  }
  deleteRow(i: number) {
    (this.DataForm.controls['Items'] as FormArray).removeAt(i);
  }

  getProductlist(index: number) {
    let formarray = (this.DataForm.controls['Items'] as FormArray);
    let formgroup = (formarray.at(index) as FormGroup);
    let product_id = formgroup.get('Product_id').value;
    let url = this.hardfunc.BaseAPIUrl + "api/ProductsAPI/GetProductDetail?product_id=" + product_id;
    this._http.get(url, this.repmodel)
      .subscribe((data1: any) => {
        console.log(data1);
        this.productlist = data1;
        this.productlist = this.productlist.map((x) => {
          let formarray = (this.DataForm.controls['Items'] as FormArray);
          let formgroup = (formarray.at(index) as FormGroup);
          formgroup.get('Product_nm').patchValue(x.Short_nm.trim());
          formgroup.get('Unit').patchValue(x.Sunit.trim());

        });
      }, err => {
        console.log(err);
      });
  }
  getDeptName() {
    let url = this.hardfunc.BaseAPIUrl + "api/Sales/deptssAPI/GetDatas";
    this._http.post(url, this.repmodel)
      .subscribe((data1: any) => {
        this.Dept_nm = data1;
      }, err => {
        console.log(err);
      });
  }
  onClear() {

  }
  createFormGroup() {
    return this.fb.group({
      Facmast_id: new FormControl(-1),
      Trantype: 'PRDC',
      fUnit: new FormControl(this.Unit),
      Issue_to: new FormControl(1),
      Tran_from: new FormControl(0),
      Tdate: new FormControl(new Date(), Validators.min(moment(new Date()).millisecond())),
      Slip_no: new FormControl(''),
      Sender_name: new FormControl(''),
      Rcvr_name: new FormControl(''),
      Remarks: new FormControl(''),
      Items: this.fb.array([this.createItem()]),

    });
  }
  createItem(): FormGroup {
    return this.fb.group({
      Ow_mast_id: new FormControl(-1),
      Product_id: [0, Validators.required],
      Product_nm: ['', Validators.required],
      Qnty: [0],
      Unit: new FormControl('NOS.'),
      Qnty2: [0],
      Unit2: new FormControl(''),
      BomItems: this.fb.array([this.createBOMItem()]),
    });
  }

  createBOMItem(): FormGroup {
    return this.fb.group({
      Ow_mast_id: new FormControl(-1),
      Product_id: [0, Validators.required],
      Product_nm: [''],
      Bom_qnt: [0],
      Stock: [0],
      Qnty: [0],
      Unit: new FormControl('NOS.'),
      Qnty2: [0],
      Unit2: new FormControl('')
    });
  }

  HelpFormGroup() {
    return this.fb.group({
      Product_id: new FormControl(),
      Product_nm: new FormControl(''),
    });
  }
}
