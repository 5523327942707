import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GenIndComponent } from './gen-ind/gen-ind.component';
import { PurchaseRoutes } from './purchase.routing';
import { RouterModule } from '@angular/router';
import { PoComponent } from './po/po.component';
import { GatInwComponent } from './gat-inw/gat-inw.component';
import { GrnComponent } from './grn/grn.component';
import { PurRetComponent } from './pur-ret/pur-ret.component';
import { PrchkListComponent } from './prchk-list/prchk-list.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TableModule } from 'primeng/table';
//import { MatTableExporterModule } from 'mat-table-exporter';
import { MatFormFieldModule, MatBadgeModule } from '@angular/material';
import { MatInputModule, MatIconModule, MatCardModule, MatPaginatorModule,MatMenuModule, MatProgressBarModule, MatButtonModule, MatListModule, MatSidenavModule, MatGridListModule, MatTooltipModule, MatToolbarModule, MatSelectModule, MatDatepickerModule, MatNativeDateModule, MatCheckboxModule, MatExpansionModule, MatDialogModule, MatTabsModule, MatTableModule, MatSortModule, MatRadioModule ,MatSlideToggleModule, MAT_DATE_LOCALE, MAT_DATE_FORMATS, DateAdapter} from '@angular/material';
import { PurdialogComponent } from './prchk-list/purdialog/purdialog.component';
import { IndentListComponent } from './indent-list/indent-list.component';
import { IndentDialogComponent } from './indent-list/indent-dialog/indent-dialog.component';
import { MatChipsModule } from '@angular/material/chips';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { IndenterCrudComponent } from './indenter-crud/indenter-crud.component';
import { IndenterListComponent } from './indenter-list/indenter-list.component';
import {MatDividerModule} from '@angular/material/divider';
import { PoregComponent } from './poreg/poreg.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { IndentComponent } from './indent/indent.component';
import { GrnRcvdComponent } from './grn-rcvd/grn-rcvd.component';
import { IndentRegComponent } from './indent-reg/indent-reg.component';
import { PoGenComponent } from './po-gen/po-gen.component';
import { JobworkOutComponent } from './jobwork-out/jobwork-out.component';

const Primeng = [
  TableModule,
]

const Material = [
  MatButtonModule,
  MatTableModule,
  MatChipsModule,
  MatIconModule,
  MatAutocompleteModule,
  MatProgressBarModule,
  MatRadioModule,
  MatBadgeModule,
  MatMenuModule,
  MatDividerModule,
  MatSelectModule,
  MatTabsModule
]
@NgModule({
  declarations: [GenIndComponent, PoComponent, GatInwComponent, GrnComponent, PurRetComponent, PrchkListComponent, PurdialogComponent, IndentListComponent, IndentDialogComponent, IndenterCrudComponent, IndenterListComponent, PoregComponent, IndentComponent, GrnRcvdComponent, IndentRegComponent, PoGenComponent, JobworkOutComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatPaginatorModule,
    Material,
    Primeng,
    ReactiveFormsModule,
    FormsModule,
    CKEditorModule,
    //MatTableExporterModule,
    MatFormFieldModule,
    MatDatepickerModule, MatInputModule,MatNativeDateModule,
    RouterModule.forChild(PurchaseRoutes)
  ],
  entryComponents: [
    PurdialogComponent,
    IndentDialogComponent,
    IndenterCrudComponent
  ]
})
export class PurchaseModule { }
