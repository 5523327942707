import { Component, OnInit, Optional, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatSnackBar } from '@angular/material';
import { ManufacturingService } from '../../manufacturing.services';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { NotificationService } from 'src/app/Services/Common/notification.service';
import { FormBuilder, FormControl, FormGroup, FormArray, FormControlName, Validators } from '@angular/forms';
import { PrdgroupCrudComponent } from '../prdgroup-crud.component';
import { MatTableDataSource } from '@angular/material';
import { SparaDialogComponent } from '../spara-dialog/spara-dialog.component';
import { AdminService } from 'src/app/Views/admin/admin.service';
import { CommonService } from 'src/app/Services/Common/common.service';
import { L } from '@angular/cdk/keycodes';
import { FromToHelpComponent } from '../from-to-help/from-to-help.component';

@Component({
  selector: 'app-prdgroup-create',
  templateUrl: './prdgroup-create.component.html',
  styleUrls: ['./prdgroup-create.component.css']
})
export class PrdgroupCreateComponent implements OnInit {

  title: string;
  DataForm: FormGroup;
  initialValues: any[];
  loading: boolean = false;
  paragrouplist: any = [];
  dataSource: MatTableDataSource<unknown>;
  displayedColumns: string[] = [];
  model: any = {};
  paragrplist: any = [];
  plist: any = [];
  paradata: any = [];
  PrdFamily: any = [];
  ParaTypelist: any = [];
  Pparalist: any = [];
  repmodel: any = {};
  PrdGroupList: any = [];
  GrpCat: any = [];

  constructor(@Optional() @Inject(MAT_DIALOG_DATA) public ReceivedData: any,
    private mnfservice: ManufacturingService,
    private hardfunc: HardFuncService,
    private notificationService: NotificationService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    @Optional() public dialogRef: MatDialogRef<PrdgroupCrudComponent>,
    private adminservice: AdminService,
    private commonservice: CommonService,
    private _snackBar: MatSnackBar) { }

  ngOnInit() {
    this.loading = true;
    this.DataForm = this.CreateFormGroup();
    this.initialValues = this.DataForm.value;
    // let ParaType = this.DataForm.get("Type").value;
    // let data = this.DataForm.value;
    // data.SubData.map(m => {
    //     m.Sales = "S";
    // });
    this.GetGroupCat()
    this.GetPrdFamily();
    this.GetParaType();
    this.GetData();
    this.ParabyGroup_id();
    // this.GetPrdGroup();

    const GrpName = this.DataForm.get('Grp_nm');
    GrpName.valueChanges.subscribe(() => {
      GrpName.patchValue(GrpName.value.toUpperCase(), { emitEvent: false });
    });    
  }
  
  // GetPrdGroup(){
  //   this.loading = true;
  //   this.repmodel.PrioLength = 4;
  //   this.repmodel.CG = 'A';
  //   this.repmodel.Status = 'P';
    
  //   this.commonservice.PrdGroupList() // 2. api/PrdGroupsAPI/GetPrdGroup -- API For --Get Product Group
  //     .subscribe(data => {
  //       console.log(data);
  //       this.PrdGroupList = data;
  //       this.loading = false;        
  //     },
  //       err => {
  //         console.log(err);
  //         this.openSnackBar("Error in Getting product group , please check connection.", "dismiss");
  //         this.loading = false;
  //       });
       
  // }
  GetData() {
    if (this.ReceivedData) {

      if (this.ReceivedData.Grp_id == -1) {
        this.title = "Add Product Group";
      }
      else {
        this.mnfservice.GetProductbyId(this.ReceivedData.Grp_id)
          .subscribe(
            (data: any) => {  
              console.log(data);
              data.Sales = data.Sales ? "S" : "Y";
              data.Purchase = data.Purchase ? "P" : "Y";
              if(data.Sales === "Y"){
                this.DataForm.get("Sales").patchValue(data.Sales);  
              }
              if(data.Purchase === "Y"){
                this.DataForm.get("Sales").patchValue(data.Purchase);  
              }
              if(data.Sales === "Y" && data.Purchase === "Y"){
                this.DataForm.get("Sales").patchValue("B");
              }             
              
              this.DataForm.patchValue(data);
              this.paragrplist = data;

              const formarr1 = new FormArray([]);

              data.SubData.map(m => {
                if (m.Ppara_id < 10000) {
                  
                  formarr1.push(
                    this.fb.group({
                      Ppara_id: m.Ppara_id,
                      Pgroup_id: m.Pgroup_id,
                      Para_nm: m.Para_nm,
                      Para_vals: m.Para_vals,
                      Priority: m.Priority,
                      Paratype: m.Paratype,
                      Parent_id: m.Parent_id,
                      Codelen: m.Codelen,
                      Mainhead: m.Mainhead,
                      Sales: m.Sales,
                      Purchase: m.Purch,
                    })
                  );
                }
                console.log(m);
              });
              this.DataForm.setControl("SubData", formarr1);
              this.title = this.ReceivedData.Grp_id + "-" + this.ReceivedData.Grp_nm;
            },
            err => {
              let errstr = this.hardfunc.getError(err);
              console.log(err);
              this.notificationService.warn(errstr);
            }
          );
      }
    }
    else {
      this.ReceivedData = {};
      this.ReceivedData.id = -1;
      this.title = "Add Product Group";
    }
  }
  
  paragrp(Type: string) {
    console.log(this.DataForm.value);
    this.plist = this.paragrplist.SubData || [];

    this.paradata = [];
    if (Type === "S") {
      this.plist.map(d => {
        if (d.Ppara_id < 10000) {
          this.paradata.push(d);

          const formarr1 = new FormArray([]);

          this.paradata.map(m => {
            formarr1.push(
              this.fb.group({
                Ppara_id: m.Ppara_id,
                Pgroup_id: m.Pgroup_id,
                Para_nm: m.Para_nm,
                Para_vals: m.Para_vals,
                Priority: m.Priority,
                Paratype: m.Paratype,
                Parent_id: m.Parent_id,
                Codelen: m.Codelen,
                Mainhead: m.Mainhead,
                //Filteryn: m.FilterSr
              })
            );
          });
          this.DataForm.setControl("SubData", formarr1);
        }
      });
    } else {
      this.plist.map(d => {
        
        if (d.Ppara_id > 10000) {
          this.paradata.push(d);
          console.log(d);
          const formarr1 = new FormArray([]);

          this.paradata.map(m => {
            formarr1.push(
              this.fb.group({
                Ppara_id: m.Ppara_id,
                Pgroup_id: m.Pgroup_id,
                Para_nm: m.Para_nm,
                Para_vals: m.Para_vals,
                Priority: m.Priority,
                Paratype: m.Paratype,
                Parent_id: m.Parent_id,
                Codelen: m.Codelen,
                Mainhead: m.Mainhead,
                //Filteryn: m.FilterSr
              })
            );
          });
          this.DataForm.setControl("SubData", formarr1);
        }
      });
    }
  }
  
  DisplayCol() {
    this.displayedColumns = ['Chk', 'Ppara_id', 'Codelen', 'Para_nm', 'Priority'];
  }
  addItems() {
    (this.DataForm.controls['SubData'] as FormArray).push(this.CreateSubData());
  }
  DeleteRow(i: number, Ppara_id: number) {
    console.log(Ppara_id);
    // (this.DataForm.controls['SubData'] as FormArray).removeAt(i);
    this.adminservice.openConfirmDialog("Are you sure you want to delete this ?")
      .afterClosed().subscribe(res => {
        if (res) {
          this.loading = true;
          const dialogRef = this.adminservice.DeletePparabyId(Ppara_id)
            .subscribe(
              (data: any) => {
                this.loading = false;
                this.notificationService.warn("Data deleted successfully");
                (this.DataForm.controls['SubData'] as FormArray).removeAt(i);
              },
              err => {
                this.loading = false;
                let errstr = this.hardfunc.getError(err);
                console.log(err);
                this.notificationService.warn(errstr);
              }
            )

          // this.notificationService('Deleted Successfully');
        }
      });
  }

  // radio button changge
  ParabyGroup_id() {
    
    let grp_id = this.ReceivedData.Grp_id;
    
    const SubDataFormArray = this.DataForm.get('SubData') as FormArray;
    
    while (SubDataFormArray.length !== 0) {
      SubDataFormArray.removeAt(0);
    }
    const sp = this.DataForm.value.Type;
    const Sales:string = sp === "S" ? "Y" : "N";
    const Purch:string = sp === "P" ? "Y" : "N";

    this.mnfservice.Pparabygroup_id(grp_id)
      .subscribe((data: any) => {
        
        this.paragrouplist = [];
          
          data.map(d => {
            console.log(d);
            if ((d.Sales === "Y" && this.DataForm.value.Type === 'S' ) ||  (d.Purch === "Y" && this.DataForm.value.Type === 'P' ))  {
              this.paragrouplist.push(d);
              console.log(d.Ppara_id);
               SubDataFormArray.push(
                   this.fb.group({                    
                     Para_nm : d.Para_nm,
                     Ppara_id: d.Ppara_id,
                     Pgroup_id: grp_id ,
                     Paratype: d.ParaType,
                     Priority: d.Priority,
                     Codelen: 2,
                     Sales: Sales,
                     Purch: Purch,
                   })
               )
            }
          });

        this.dataSource = new MatTableDataSource(<any>this.paragrouplist);
        this.DisplayCol();
      });
  }

  SaveData() {
    this.loading = true;
    let ParaType = this.DataForm.get("Type").value;
    // this.DataForm.get("Grp_Code").value.toUpperCase();

    let salepurch =  this.DataForm.get("Sales").value;
    if(salepurch === "S"){
      this.DataForm.get("Sales").patchValue("Y");
    }else{
      this.DataForm.get("Sales").patchValue("N");
    } 
    if(salepurch === "P"){
      this.DataForm.get("Purchase").patchValue("Y");
    }else{
      this.DataForm.get("Purchase").patchValue("N");
    } 
    if(salepurch === "B"){
      this.DataForm.get("Sales").patchValue("Y");
      this.DataForm.get("Purchase").patchValue("Y");
    }
    
    let data = this.DataForm.value;
    
    data.SubData.map(m => {

      if (ParaType === "S") {
        m.Sales = "Y";
      } else {
        m.Sales = "N";
      }
      if (ParaType === "P") {
        m.Purch = "Y";
      } else {
        m.Purch = "N";
      }
      if (ParaType === "B") {
        m.Sales = "Y";
        m.Purch = "Y";
      }
    });
    console.log(this.DataForm.value);

    this.mnfservice.SavePrdgroup(this.DataForm.value)
      .subscribe((data: any) => {
        this.ParabyGroup_id();
        this.notificationService.success(' Data Saved Successfully... ');
        this.onClose();
        this.loading = false;
      },
        err => {
          let errstr = this.hardfunc.getError(err);
          this.notificationService.warn(errstr);
          this.loading = false;
        });
  }
  
  CheckProdRange(){
    let fprod_id = this.DataForm.get("fProd_id").value;
    let tprod_id = this.DataForm.get("tProd_id").value;
    this.mnfservice.CheckPrdRange(fprod_id, tprod_id)
      .subscribe((data: string) => {
        console.log(data);
        if(data != "")
        this.adminservice.openWarningDialog("Product range already under Utiliazation",'p')
        .afterClosed().subscribe(res =>{
          if(res){
          this.notificationService.warn('already existed...');
          }
        });
      },
      )
      // err => {
      //   let errstr = this.hardfunc.getError(err);
        
      // };
  }

  GetPparadata() {
    this.loading = true;
    this.mnfservice.GetPparabyId(this.ReceivedData.Grp_id)
      .subscribe(
        (data: any) => {
          console.log(data)
                  
          const SubDataFormArray = this.DataForm.get('SubData') as FormArray;
      
          const sp = this.DataForm.value.Type;
          const Sales:string = sp === "S" ? "Y" : "N";
          const Purch:string = sp === "P" ? "Y" : "N";
          data.map(d => {
          SubDataFormArray.push(
            this.fb.group({
              Para_nm : d.para_nm,
              Ppara_id: d.ppara_id,
              Pgroup_id: this.ReceivedData.Grp_id,
              Paratype: d.ParaType,
              Priority: d.priority,
              Accessory: d.Accessory,
              Codelen: 2,
              Sales: Sales,
              Purch: Purch,
            })
          )
        });
        },
        err => {
          this.loading = false;
          let errstr = this.hardfunc.getError(err);
          console.log(err);
          this.notificationService.warn(errstr);
        }
      );
  }
  GetPrdFamily() {
    this.loading = true;
    this.commonservice.CodeList('ProdFam')
      .subscribe(
        (data: any) => {
          this.PrdFamily = JSON.parse(data);
          this.loading = false;
        },
        err => {
          this.loading = false;
          let errstr = this.hardfunc.getError(err);
          this.notificationService.warn(errstr);
        }
      );
  }
  GetGroupCat() {
    this.loading = true;
    this.commonservice.CodeList('GrpCat')
      .subscribe(
        (data: any) => {
          console.log(data);
          this.GrpCat = JSON.parse(data);
          this.loading = false;
        },
        err => {
          this.loading = false;
          let errstr = this.hardfunc.getError(err);
          this.notificationService.warn(errstr);
        }
      );
  }
  GetParaType() {
    this.loading = true;
    this.commonservice.CodeList('ParaType')
      .subscribe(
        (data: any) => {
          this.ParaTypelist = JSON.parse(data);
          this.loading = false;
        },
        err => {
          this.loading = false;
          let errstr = this.hardfunc.getError(err);
          this.notificationService.warn(errstr);
        }
      );
  }
  OpenDialog(Ppara_id: number, Para_nm: string) {
    const dialogRef = this.dialog.open(SparaDialogComponent, {
      width: '650px',
      height: '700px',
      data: { Ppara_id: Ppara_id, Para_nm: Para_nm, Grp_id: this.ReceivedData.Grp_id }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
    });
  }
  DialogFromTo(){
    const dialogRef = this.dialog.open(FromToHelpComponent, {
      width: '650px',
      height: '600px',
      // data: { Ppara_id: Ppara_id, Para_nm: Para_nm, Grp_id: this.ReceivedData.Grp_id }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
    });
  }
  ResetData() {
    if (this.ReceivedData.id > 0) {
      this.dialogRef.close();
    }
    else {
      this.DataForm.reset();
      this.DataForm.patchValue(this.initialValues);
      Object.keys(this.DataForm.controls).forEach(key => {
        this.DataForm.get(key).setErrors(null);
      });
    }
  }
  onClose() {
    if (this.ReceivedData.Grp_id > 0) {
      this.dialogRef.close();
    }
    else {
      this.DataForm.reset();
      Object.keys(this.DataForm.controls).forEach(key => {
        this.DataForm.get(key).setErrors(null);
      });
    }
  }
  CreateFormGroup() {
    return this.fb.group({
      Family_id: new FormControl(),
      Grp_id: new FormControl(-1),
      Grp_nm: new FormControl(''),
      Grp_Code: new FormControl(''),
      Sales: new FormControl('S'),
      Purchase: new FormControl('P'),
      Type: new FormControl('S'),
      fProd_id: new FormControl(),
      tProd_id: new FormControl(),
      Category: new FormControl('',Validators.required),
      SubData: this.fb.array([this.CreateSubData()]),
    });
  }
  CreateSubData(): FormGroup {
    //   if (radio = "S")
    //   Sales = 'Y';
    //  Puch = "N";
    //  else
    return this.fb.group({
      Ppara_id: [-1],
      Pgroup_id: [-1],
      Para_nm: [''],
      // Para_vals: [''],
      Paratype: [''],
      Priority: [0],
      Parent_id: [],
      Codelen: [],
      Sales: ['', Validators.required],
      Purch: ['', Validators.required],
    });
  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
}
