import { Component, OnInit, Optional, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PendordAddfileComponent } from '../pendord-addfile/pendord-addfile.component';
import { FormGroup, FormControl, Validators, FormBuilder, RequiredValidator, FormArray } from '@angular/forms';
import { DmsService } from 'src/app/Services/Common/dms.service';
import { NotificationService } from 'src/app/Services/Common/notification.service';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material';
import { CommonService } from 'src/app/Services/Common/common.service';
import { PendordService } from '../pendord.service';
import { AdminService } from 'src/app/Views/admin/admin.service';
import { EnqService } from '../../Enq/Enq.service';
import { SalesService } from '../../sales.service';

@Component({
  selector: 'app-pendord-attach',
  templateUrl: './pendord-attach.component.html',
  styleUrls: ['./pendord-attach.component.css']
})
export class PendordAttachComponent implements OnInit {
  loading: boolean = false;
  DataForm: FormGroup;
  file: any;
  datalist: any = [];
  repmodel: any = {};
  datasource: any = [];
  dataSource: MatTableDataSource<unknown>;
  Docu_List: any = [];
  EMD_List: any = [];
  BG_List: any = [];
  TemplateForm: FormGroup;
  isClicked = false;
  initialValues: any[];
  ModeOfEnquiry: any = [];
  EnqData: FormGroup;
  Username: string = '';
  ExpDispatch: FormGroup;
  ExternalArr: any = [];

  OnClick(Docu_id: number, Remarks: string) {
    this.isClicked = true;
    this.TemplateForm.controls["Remarks"].patchValue(Remarks);
    this.TemplateForm.controls["Docu_id"].patchValue(Docu_id);
  }

  constructor(private dialog: MatDialog,
                private fb: FormBuilder,
                private dmsservice: DmsService,
                private notificationService: NotificationService,
                private hardfunc: HardFuncService,
                private _http: HttpClient,
                private _snackBar: MatSnackBar,
                @Optional() @Inject(MAT_DIALOG_DATA) public ReceivedData: any,
                private pendordservice: PendordService,
                private commonservice: CommonService,
                private notificationservice: NotificationService,
                @Optional() public dialogRef: MatDialogRef<PendordAttachComponent>,
                private adminservice: AdminService,
                private EnqService: EnqService,
                private SalesService: SalesService
              ) { }

  ngOnInit() {
    this.Username = this.hardfunc.UserName;
    this.DataForm = this.createFormGroup();
    this.TemplateForm = this.RemarkEditFormGroup();
    this.EnqData = this.EnqDataForm();
    this.ExpDispatch = this.ExpDispFormGroup();
    this.loading = true;
    this.GetData();
    this.getDocuList();
    this.getModeOfEnq();
    //this.getEnqPartialdata();
    this.initialValues = this.DataForm.value;
    this.initialValues = this.TemplateForm.value;
  }
  Docudetail(vals: string) {
    if (this.ReceivedData.Vtype === 'QP') {
      var docu = "";
      if (vals === 'Datasheet') {
        docu = 'd';
      } else {
        docu = 'w';
      }
      let filename = parseInt(1000 + this.ReceivedData.Grp_id).toString().substring(1, 4) + this.ReceivedData.Grp_nm.substring(0, 2) +
        "_" + (this.ReceivedData.Ordno + 1000).toString().substring(1) + "_" + docu;
      // Find last no.
      // +1 
      let Last_number = 0;
      this.datalist.map(m => {
        if (m.Remarks.substring(0, 6) === filename.substring(0, 6)) {
          let nums = parseInt(m.Remarks.substring(7));
          if (!isNaN(nums) && nums > Last_number) {
            Last_number = nums;
          }
        }
      });
      Last_number++;
      filename += (1000 + Last_number).toString().substring(1, 4);
      this.DataForm.controls['Remarks'].patchValue(filename);
    }
  }
  createFormGroup() {
    return this.fb.group({
      Vtype: new FormControl(this.ReceivedData.Vtype, Validators.required),
      Yrx: new FormControl(''),
      Firmx: new FormControl(this.hardfunc.Firmx),
      Ordno: new FormControl(this.ReceivedData.Ordno),
      Oloc: new FormControl(this.ReceivedData.Oloc),
      Notes_type: new FormControl(this.ReceivedData.Notes_type),
      Docu_type: new FormControl('', Validators.required),
      Remarks: new FormControl(''),
      file: new FormControl(''),
      Docu_name: new FormControl(''),    
      ExtenalUrl: new FormControl(''),
    });
  }
  ExpDispFormGroup() {
    return this.fb.group({
      Firm: new FormControl(this.hardfunc.Firmx),
      Ordno: new FormControl(this.ReceivedData.Ordno),
      Oloc: new FormControl(this.ReceivedData.Oloc),
      Yrx: new FormControl(this.hardfunc.Yrx),
      exp_disp_dt: new FormControl(new Date())
    });
  }
  EnqDataForm() {
    return this.fb.group({
      Srno: new FormControl(this.ReceivedData.Ordno),
      //Enq_id: new FormControl(-1),
      Status: new FormControl(''),
      No_of_quot: new FormControl(),
      D_o_o: new FormControl(new Date()),
      Rem: new FormControl(''),
      Prio: new FormControl('L')
    });
  }
  QuoteDataForm() {
    return this.fb.group({
      Srno: new FormControl(this.ReceivedData.Ordno),
      //Enq_id: new FormControl(-1),
      Status: new FormControl(''),
      No_of_quot: new FormControl(),
      D_o_o: new FormControl(new Date()),
      Rem: new FormControl(''),
      Prio: new FormControl('L')
    });
  }
  // 1. api/DMSAPI/SaveDocu -- API For --Save Document.
  upload() {
    this.loading = true;    
    // console.log(this.DataForm.value);
    // console.log(this.ExternalArr);
    // this.ExternalArr.map(w => {
    //   console.log(w);
    // })
    var formData = new FormData();
    formData.append("Files", this.file);
    formData.append("Data", JSON.stringify(this.DataForm.value));
    console.log(formData);
    this.dmsservice.SaveDocu(formData)
      .subscribe(
        data => {
          this.GetData();
          this.ResetData();
          this.loading = false;
          this.notificationService.success(' Data Saved Successfully... ');
        },
        err => {
          let errstr = this.hardfunc.getError(err);
          this.notificationService.warn(errstr);
          this.loading = false;
        }
      )
  }
  SaveEnclosure(){
    console.log(this.DataForm.value);
    // this.SalesService.SaveEnclosures()
    //   .subscribe((data: any) => {
    //     console.log(data);
    //   });
  }
  // SaveExternalUrl(){
  //   this.dmsservice.SaveExternalUrl()
  //     .subscribe((data: any) => {
  //       console.log(data);
  //     });
  // }
  // ExternalAddi(){
  //   // console.log(this.DataForm.get('ExtenalUrl').value);
  //   this.ExternalArr = this.DataForm.get('ExtenalUrl').value;
  //   console.log(this.ExternalArr);
  //   // this.DataForm.get('ExtenalUrl').patchValue(externalUrl);
  //   // console.log(this.DataForm.get('ExtenalUrl').value);
  // }
  expeDispDt() {
    console.log(this.ExpDispatch.value);
    this.ExpDispatch.controls.exp_disp_dt.patchValue(this.hardfunc.hsConvDt(this.ExpDispatch.controls["exp_disp_dt"].value));
    this.pendordservice.ExpDispatchDt(this.ExpDispatch.value)
      .subscribe(
        data1 => {
          this.notificationService.success(' Data Saved Successfully...');
          this.loading = false;
        },
        err => {
          let errstr = this.hardfunc.getError(err);
          console.log(err);
          this.notificationService.warn(errstr);
          this.loading = false;
        }
      )
  }
  EnqSaveData() {
    console.log(this.EnqData.value);
    this.loading = true;
    this.EnqData.controls.D_o_o.patchValue(this.hardfunc.hsConvDt(this.EnqData.controls["D_o_o"].value));
    this.EnqService.SavePartialData(this.EnqData.value)
      .subscribe(
        data1 => {
          this.notificationService.success(' Data Saved Successfully...');
          this.loading = false;
        },
        err => {
          let errstr = this.hardfunc.getError(err);
          console.log(err);
          this.notificationService.warn(errstr);
          this.loading = false;
        }
      )
  }
  getEnqPartialdata() {
    this.loading = true;
    this.EnqService.GetPartialdata(this.ReceivedData.Ordno)
      .subscribe(
        (data: any) => {
          console.log(data);
          data.Status = data.Status.trim();
          this.EnqData.patchValue(data);
          //this.title = "EDITING";
          return false;
        },
        err => {
          let errstr = this.hardfunc.getError(err);
          console.log(err);
          this.notificationService.warn(errstr);
        }
      )
  }
  GetEnqEmail() {
    this.loading = true;
    this.datalist = [];
    this.EnqService.GetForPendordAttEmail(this.ReceivedData.Ordno)
      .subscribe(
        (data: any) => {
          console.log(data);
          this.loading = false;
          this.GetData();
          this.notificationservice.success('Email Sent Successfully...');
        },
        err => {
          if (err.error.error_description) {
            this.openSnackBar(err.error.error_description, "dismiss");
          }
          else {
            this.openSnackBar("Error in getting data , please check server connectivity...  ", "dismiss");
          }
        }
      )
  }
  getModeOfEnq() {
    this.loading = true;
    this.commonservice.CodeList('MOI')
      .subscribe(
        (data: any) => {
          this.ModeOfEnquiry = JSON.parse(data);
          this.loading = false;
        },
        err => {
          this.loading = false;
          let errstr = this.hardfunc.getError(err);
          this.notificationService.warn(errstr);
        }
      );
  }
  // 2. api/DMSAPI/SaveDesc -- API For --Save Description.
  Update_Remarks(Docu_id: number, Remarks: string) {
    this.pendordservice.SaveDesc(Docu_id, Remarks)
      .subscribe(
        data => {
          this.loading = false;
          this.notificationService.success(' Data Saved Successfully... ');
          this.GetData();
        },
        err => {
          let errstr = this.hardfunc.getError(err);
          this.notificationService.warn(errstr);
          this.loading = false;
        }
      )
  }
  
  RemarkEditFormGroup() {
    return this.fb.group({
      Docu_id: new FormControl(-1),
      Remarks: new FormControl('')
    });
  }

  //3.api/DMSAPI/GetDatabyOrdno --API For --Get Data By Order No.
  GetData() {
    this.loading = true;
    this.datalist = [];
    if (this.ReceivedData) {      
      this.dmsservice.GetDocu(this.hardfunc.Firmx, this.ReceivedData.Ordno, this.ReceivedData.Oloc, this.ReceivedData.Vtype,
        this.ReceivedData.Wmodule_id)
        .subscribe(
          (data1: any) => {
            console.log(data1);
            data1.map(m => {                          
              m.isClicked = false;              
            });
            this.datalist = data1;
            this.dataSource = new MatTableDataSource(<any>data1);
            this.loading = false;
            this.LoadImages();
          },
          err => {
            let errstr = this.hardfunc.getError(err);
            this.notificationService.warn(errstr);
          }
        )
    }
    else {
      this.ReceivedData = {};
    }
  }

  LoadImages() {
    this.datalist.map(m => {       
      console.log(m);
      if(!m.ExtenalUrl){
        if (m.Filename.indexOf('.pdf') > 0 || m.Filename.indexOf('.PDF') > 0) {
          m.FileType = "PDF";
        }
        else if (m.Filename.toLowerCase().indexOf('.jpg') > 0 || m.Filename.toLowerCase().indexOf('.jpeg') > 0) {
          m.FileType = "IMG";
          this.LoadImagebyId(m)
        }
        else {
          m.FileType = "OTH";
        }
  
      }
    }
    )
  }

  LoadImagebyId(objFile: any) {
    this.loading = true;
    this.dmsservice.LoadImagebyId(objFile.Docu_id)
      .subscribe(
        (data1: any) => {
          objFile.ImageStr = 'data:image/jpeg;base64,' + data1;
          this.loading = false;
        },
        err => {
          this.loading = false;
          let errstr = this.hardfunc.getError(err);
          this.notificationService.warn("Error in image loading " + errstr);
        }
      )
  }

  //4. api/Common/CodemastsAPI/GetCodeListbyCodenm -- API For --Get Code List by Code master
  getDocuList() {
    this.loading = true;
    var str = "";
    if (this.ReceivedData.Vtype === 'OR') {
      str = 'DocuType';
    }
    if (this.ReceivedData.Vtype === 'EM') {
      str = 'EMD';
    }
    if (this.ReceivedData.Vtype === 'BG') {
      str = 'BG';
    }
    if (this.ReceivedData.Vtype === 'EN') {
      str = 'Enq_docu';
    }
    if (this.ReceivedData.Vtype === 'QP') {
      str = 'Quote_Prd';
    }
    if (this.ReceivedData.Vtype === 'HR') {
      str = 'EMP_DOCU';
    }
    if (this.ReceivedData.Vtype === 'PA') {
      str = 'PROD_ATT';
    }
    if (this.ReceivedData.Vtype === 'CR') {
      str = 'CR_Docu';
    }
    if (this.ReceivedData.Vtype === 'RC') {
      str = 'CR_Docu';
    }
    this.commonservice.CodeList(str)
      .subscribe(
        (data: any) => {     
          console.log(data);
          this.Docu_List = JSON.parse(data);
          this.loading = false;
        },
        err => {
          this.loading = false;
          let errstr = this.hardfunc.getError(err);
          this.notificationservice.warn(errstr);
        }
      );
  }

  //5. api/DMSAPI/DeleteData -- API For Delete Attachment
  Delete_docu(Docu_id: number) {
    this.loading = true;
    this.adminservice.openConfirmDialog("Are you sure you want to delete this ?")
      .afterClosed().subscribe(res => {
        this.loading = false;
        if (res) {
          const dialogRef = this.dmsservice.Delete_docu(Docu_id)
            .subscribe(
              (data: any) => {
                this.loading = false;
                this.notificationService.warn("Data deleted succefully");
                this.GetData();
              },
              err => {
                this.loading = false;
                let errstr = this.hardfunc.getError(err);
                console.log(err);
                this.notificationService.warn(errstr);
              }
            )
        }
      });
  }
  ResetData() {
    if (this.ReceivedData.id > 0) {
      //this.dialogRef.close();
      console.log('Dialog');
    }
    else {
      this.DataForm.reset();
      this.DataForm.patchValue(this.initialValues);
      Object.keys(this.DataForm.controls).forEach(key => {
        this.DataForm.get(key).setErrors(null);
      });
    }
  }
  TemplateReset() {
    this.TemplateForm.reset();
    this.TemplateForm.patchValue(this.initialValues);
    Object.keys(this.TemplateForm.controls).forEach(key => {
      this.TemplateForm.get(key).setErrors(null);
    });
  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
  GetFile(event) {
    this.file = event.target.files[0];
    console.log("file", this.file);
  }
  openDialog(Ordno: number, Oloc: number): void {

    const dialogRef = this.dialog.open(PendordAddfileComponent, {
      width: '930px',
      height: '296px',
      data: { Ordno: Ordno, Oloc: Oloc }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
}
