import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormArray } from '@angular/forms';
import { CommonService } from 'src/app/Services/Common/common.service';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { PurchaseService } from '../purchase.service';
import { NotificationService } from 'src/app/Services/Common/notification.service';

@Component({
  selector: 'app-jobwork-out',
  templateUrl: './jobwork-out.component.html',
  styleUrls: ['./jobwork-out.component.css']
})
export class JobworkOutComponent implements OnInit {

  DataForm: FormGroup;
  repmodel: any = {};
  Productlist: any = [];
  loading: boolean = true;

  constructor(private fb: FormBuilder,
    private commonservice: CommonService,
    private hardfunc: HardFuncService,
    private _http: HttpClient,
    private PurchaseService: PurchaseService,
    private NotificationService: NotificationService) { }

  ngOnInit() {
    this.loading = true;
    this.DataForm = this.CreateFormGroup();
  }
  SaveData() {
    this.loading = true;
    console.log(this.DataForm.value);
    // this.PurchaseService.SaveJwOut(this.DataForm.value)
    //   .subscribe((data: any) => {
    //     console.log(data);
    //     this.ResetData();
    //     this.NotificationService.success("Indent Saved Successfully... ");
    //     this.loading = false;
    //   });
  }
  addItems() {
    (this.DataForm.controls['SubDatas'] as FormArray).push(this.CreateSubData());
  }
  DeleteRow(i: number,JWtr_id: number) {
    console.log(JWtr_id);
    // (this.DataForm.controls['SubDatas'] as FormArray).removeAt(i);
    // let formarray = (this.DataForm.controls['SubDatas'] as FormArray);
    // let formgroup = (formarray.at(i) as FormGroup);
    // formgroup.get('Noted').patchValue('D');    
  }
  GetPartyDetail() {
    this.commonservice.GetPartyDetail(this.DataForm.get("Pmst_cod").value, this.hardfunc.Firmx)
      .subscribe(
        (data: any) => {
          data.map(d => {
            this.DataForm.get("Party_name").patchValue(d.PARTY_NAME);
          });
        },
        err => {

        }
      )
  }
  GetPrdDetail(index: number) {
    let formarray = (this.DataForm.controls['SubDatas'] as FormArray);
    let formgroup = (formarray.at(index) as FormGroup);
    let product_id = formgroup.get('Product_id').value;
    let firmx = this.hardfunc.Firmx;
    let yrx = this.hardfunc.Yrx;
    let url = this.hardfunc.BaseAPIUrl + "api/ProductsAPI/PrdWithStock?product_id=" + product_id +
      "&tranfromId=" + 1 + "&Firmx=" + firmx + "&Yrx=" + yrx;
    this._http.get(url, this.repmodel)
      .subscribe((data1: any) => {
        this.Productlist = data1;
        this.Productlist = this.Productlist.map((x) => {
          let formarray = (this.DataForm.controls['SubDatas'] as FormArray);
          let formgroup = (formarray.at(index) as FormGroup);

          formgroup.get('Item_nm').patchValue(x.Short_nm.trim());
          formgroup.get('Unit').patchValue(x.Sunit);
        });
      }, err => {
        console.log(err);
      });
  }
  GetChallan() {
    this.PurchaseService.GetChallan(this.DataForm.get("Entryno").value)
      .subscribe((data: any) => {        
        data.map(d => {                   
          let str = d.TDATE;
          let res: Date = new Date(str);    

          this.DataForm.get("Tdate").patchValue(this.formatDate(new Date(res)));
          this.DataForm.get("Pmst_cod").patchValue(d.PMST_COD);
          this.DataForm.get("Store_id").patchValue(d.STORE_ID);
          this.DataForm.get("Grnno").patchValue(d.GRNNO);
          this.DataForm.get("Cvat_srno").patchValue(d.CVAT_SRNO);
          this.DataForm.get("Eno").patchValue(d.ENTRYNO);
          this.DataForm.get("Rem").patchValue(d.REM);
          this.DataForm.get("Tran_id").patchValue(d.TRAN_ID);
          this.DataForm.get("Rcvd").patchValue(true);

          d.SubDatas = [];
          this.PurchaseService.jw_tranbyTran_id(d.TRAN_ID)
            .subscribe((data1: any) => {
              data1.map(m => {
                d.SubDatas.push(m);                
                const formarr = new FormArray([]);
                d.SubDatas.map(m => {                  
                  formarr.push(
                    this.fb.group({
                      JWtr_id: m.JWtr_id,
                      Srno: m.srno,
                      Product_id: m.product_id,
                      Item_nm: m.item_nm,
                      Process: m.process.trim(),
                      Qnt: m.qnt,
                      Unit: m.Unit,
                      Weight: m.weight,
                      Amount: m.amount,
                      Descri: m.descri,
                    }),
                  );
                  this.DataForm.setControl("SubDatas", formarr);
                  this.loading = false;
                });
              })
            });           
        });
        
      });
  }

  ResetData() {
    this.DataForm.reset();
  }
  CreateFormGroup() {
    return this.fb.group({
      Tran_id: new FormControl(-1),
      Ex: new FormControl('J'),
      Entryno: new FormControl(),
      Tdate: new FormControl(''),
      Pmst_cod: new FormControl(''),
      Party_name: new FormControl(''),
      Rcvd: new FormControl(true),
      Store_id: new FormControl(1),
      Grnno: new FormControl(),
      Cvat_srno: new FormControl(),
      Eno: new FormControl(),
      Rem: new FormControl(''),
      SubDatas: this.fb.array([this.CreateSubData()]),
    });
  }
  CreateSubData(): FormGroup {
    return this.fb.group({
      JWtr_id: new FormControl(-1),
      Srno: new FormControl(1),
      Product_id: new FormControl(''),
      Item_nm: new FormControl(''),
      Process: new FormControl(''),
      Qnt: new FormControl(),
      Unit: new FormControl(''),
      Weight: new FormControl(''),
      Amount: new FormControl(),
      Descri: new FormControl(''),
      Noted: new FormControl('')
    });
  }
  private formatDate(date) {        
    const d = new Date(date);    
    let month = '' + (d.getMonth() + 1);    
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;    
    return [year, month, day].join('-');
  }
}
