import { Routes } from '@angular/router';
import { GenIndComponent } from './gen-ind/gen-ind.component';
import { PoComponent } from './po/po.component';
import { GatInwComponent } from './gat-inw/gat-inw.component';
import {GrnComponent} from './grn/grn.component';
import { PurRetComponent } from './pur-ret/pur-ret.component';
import { PrchkListComponent } from './prchk-list/prchk-list.component';
import { IndentListComponent } from './indent-list/indent-list.component';
import { IndenterCrudComponent } from './indenter-crud/indenter-crud.component';
import { IndenterListComponent } from './indenter-list/indenter-list.component';
import { PoregComponent } from './poreg/poreg.component';
import { IndentComponent } from './indent/indent.component';

 export const PurchaseRoutes: Routes = [
      {
        path: 'genInd',
        component: GenIndComponent,
        data: { title: 'General Indent' }
      },
      {
        path: 'po',
        component: PoComponent,
        data: { title: 'PO' }
      },
      {
        path: 'gatInw',
        component: GatInwComponent,
        data: { title: 'Gate Inward' }
      },
      {
        path: 'grn',
        component: GrnComponent,
        data: { title: 'GRN' }
      },
      {
        path: 'purRet',
        component: PurRetComponent,
        data: { title: 'Purchase Return' }
      },
      {
        path: 'PurCheckList',
        component: PrchkListComponent,
        data: { title: 'PurCheckList' }
      },
      {
        path: 'Indent',
        component: IndentComponent,
        data: { title: 'Indent' }
      },
      {
        path: 'Indenter-list',
        component: IndenterListComponent,
        data: { title: 'Indenter-list' }
      },
      {
        path: 'Poreg',
        component: PoregComponent,
        data: { title: 'Poreg' }
      }
 ];
