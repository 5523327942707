import { Component, OnInit, ViewChild, Optional, Inject, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { CommonService } from 'src/app/Services/Common/common.service';
import { Router } from '@angular/router';
import { SalesService } from '../sales.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OrderInvComponent } from '../order-inv/order-inv.component';
import { MatSnackBar } from '@angular/material';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Sort } from '@angular/material';
import { MatPaginator } from '@angular/material';
import { FormControl, FormGroup, FormBuilder, FormControlDirective } from '@angular/forms';
import { PendordDialogComponent } from './pendord-dialog/pendord-dialog.component';
import { PendordAttachComponent } from './pendord-attach/pendord-attach.component';
import { NotificationService } from 'src/app/Services/Common/notification.service';
import { PendordService } from './pendord.service';
import { AdminService } from 'src/app/Views/admin/admin.service';
import * as moment from 'moment'
import { BOMComponent } from '../../manufacturing/bom/bom.component';
import { OsdetailComponent } from '../osdetail/osdetail.component';
import { MatSelect } from '@angular/material/select';
import { DmsService } from 'src/app/Services/Common/dms.service';
import { GrpBalanceComponent } from '../../shared/grp-balance/grp-balance.component';
import * as   XLSX from 'xlsx';

@Component({
  selector: 'app-pendord',
  templateUrl: './pendord.component.html',
  styleUrls: ['./pendord.component.css']
})
export class PendordComponent implements OnInit, OnDestroy {
  loading: boolean = false;
  datalist: any = [];
  cols: any[];
  repmodel: any = {};
  ProdList: any = [];
  PrdGroupList: any = [];
  PrdStockList: any = [];
  msgs: any[] = [];
  rowGroupMetadata: any;
  datasource: any = [];
  dataSource: MatTableDataSource<unknown>;
  displayedColumns: string[] = [];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  @ViewChild(MatTable, { static: false }) table!: MatTable<any>;
  @ViewChild(MatTable, { static: false }) Cat1!: MatTable<any>;
  @ViewChild(MatTable, { static: false }) Cat2!: MatTable<any>;
  @ViewChild(MatTable, { static: false }) Cat3!: MatTable<any>;
  @ViewChild('select', { static: false }) select: MatSelect;

  DirectorList: any = [];
  //Dname: string;
  StateList: any = [];
  DataForm: FormGroup;
  Dir_id: number = 0;
  Ptpenord: any = [];
  Totsal1: number;
  PtpenordAll: any = [];
  Handle_id: number = 0;
  MarketingList: any = [];
  isShown: boolean = false;
  Ord_Status: any = [];
  Noti_Array: any = [];
  no_of_msg: number = 0;
  firmx: string;
  DashData: any = [];
  Ordnos: number;
  Olocs: number;
  isClicked = false;
  isClickedCat1 = false;
  isClickedCat2 = false;
  isClickedCat3 = false;
  isClickedOrdStatus = false;
  
  CateClicked: boolean = false;

  Username: string;
  mktname: string;
  dir: string;
  OrderCate: string = "";
  isDisabled: boolean = true;
  Permission: string = "NNNNNNNNNN";
  selected: string[] = ['Select all'];
  PqntyTotal: number;
  StatusValue: any = [];
  FlagUserYN: any = [];
  FlagRights: string;
  PDFUserYN: any = [];
  PDFRights: string;
  PartyPO: any = [];
  PartyPoRights: string;
  ExcelRights: any = [];
  ExcelRightUser: string;

  @ViewChild('testselect', { static: true }) myselect: MatSelect;
  // @ViewChild('testselect') private myselect:NgSelectComponent;
  filename: string = 'Sales-Order.xlsx';
  Rights: any = {
    Domestic: false,
    Foreign: false,
    CriGrp: false,
    Chat: false,
    Att: false,
    Corr: false,
    OrderDetail: false
  }

  constructor(
              private _http: HttpClient,
              private hardfunc: HardFuncService,
              private _cservice: CommonService,
              private _router: Router,
              private sal_service: SalesService,
              public dialog: MatDialog,
              private _snackBar: MatSnackBar,
              private fb: FormBuilder,
              private commonservice: CommonService,
              private notificationservice: NotificationService,
              @Optional() @Inject(MAT_DIALOG_DATA) public ReceivedData: any,
              private pendordservice: PendordService,
              private adminservice: AdminService,
              private dmsserivce: DmsService
            ) { }

  ngOnInit() {
    this.getHandles();
    this.getMarketing();
    this.getState();
    this.getOrd_Status();
    this.getNotification(); //Notification
    this.getAttachment();   //Get Attachment
    // this.getOrdDetails();   //Get Order Details
    this.loading = true;
    //Specific 
    this.repmodel.Priority = "*All*";

    this.Username = this.hardfunc.UserName.toLowerCase();
    this.FlagUserYN = ['alpesh', 'hardik', 'hms', 'ams', 'vaibhav', 'janaki', 'krusha', 'anita', 'umesh', 'sweta', 'chandani'];
    this.FlagUserYN.forEach(element => {
      if (this.Username === element) {
        this.FlagRights = element;
      }
    });
    this.PartyPO = ['hms', 'ashok', 'jimit', 'ams', 'sagar', 'shetul', 'sujal', 'mms', 'hardik','alpesh'];
    this.PartyPO.forEach(element => {
      if(this.Username === element){
        this.PartyPoRights = element;
      }      
    });
    this.ExcelRights = ['alpesh','hms','hardik'];
    this.ExcelRights.forEach(element => {
      if(this.Username === element){
        this.ExcelRightUser = element;
      }
    });

    this.PDFUserYN = ['hms', 'ashok', 'jimit', 'ams', 'sagar', 'shetul', 'sujal', 'mms', 'alpesh', 'anita', 'hemali',
      'vaibhav', 'janaki', 'krusha','yogin','ravi','anal','nidhi','khushi','upasana','sweta','rekha.tc','hiren'];
    this.PDFUserYN.forEach(pdf => {
      if (this.Username === pdf) {
        this.PDFRights = pdf;
      }
    });
    // 1. api/Admin/WmodulesAPI/getModulesbyUserAndWmodule_id -- API For --Get Models By Username
    // 1078 - Party wise O/s
    this.Permission = this.hardfunc.GetLocalPermissionbyModule_id(1038);
    this.adminservice.getModulesbyUserAndWmodule_id(this.hardfunc.UserName, 1078)
      .subscribe(
        (data: any) => {
          data = JSON.parse(data);
          var permission = data[0].Permission;
          this.Rights.OrderDetail = permission.substring(0, 1) === "Y" ? true : false;            
        },
        err => {
          this.notificationservice.warn('Error in getting data...');
        }
      )

    // 1038 - Sales Order
    this.adminservice.getModulesbyUserAndWmodule_id(this.hardfunc.UserName, 1038)
      .subscribe(
        (data: any) => {
          data = JSON.parse(data);
          var permission = data[0].Permission;

          this.Rights.Domestic = permission.substring(1, 2) === "Y" ? true : false;
          this.Rights.Foreign = permission.substring(2, 3) === "Y" ? true : false;
          this.Rights.CriGrp = permission.substring(3, 4) === "Y" ? true : false;
        },
        err => {
          this.notificationservice.warn('Error in getting data...');
        }
      )

    this.repmodel.CG = 'A';
    this.repmodel.Status = 'P';
    this._cservice.PrdGroupList() // 2. api/PrdGroupsAPI/GetPrdGroup -- API For --Get Product Group
      .subscribe(data => {        
        this.PrdGroupList = data;        
        this.loading = false;
      },
        err => {
          console.log(err);
          this.openSnackBar("Error in Getting product group , please check connection.", "dismiss");
          this.loading = false;
        });

    this.cols = [
      { field: 'Ordno', header: 'Order No./ Date' },
      { field: 'Ordnos', header: 'Party PO' },
      { field: 'Party_name', header: 'Other Details ' },
      { field: 'Srno', header: 'Sr.' },
      { field: 'Pro_detail', header: 'Item Name' },
      { field: 'Qnty', header: 'Qnt' },
      { field: "Pqnty", header: 'PQNTY' },
      { field: "Deli_dts", header: 'Deli dt' },
      { field: "Ins_yn", header: 'INSP' },
      { field: "Bg_yn", header: 'BG' },
      { field: "Ld_yn", header: 'LD Pen' },
      { field: "Consignee", header: 'Consignee' },
      { field: "Payterms", header: 'PayTerms' },
      { field: 'Rate', header: 'Rate' },
      { field: 'Color', header: 'Color' }
    ];

    this.DataForm = this.createFormGroup();
    this.loading = true;
    let myItem1 = localStorage.getItem("Firmx") || "";
    if (myItem1 != "null" && myItem1.length > 0) {
      this.firmx = myItem1;
    } else {
      this.firmx = "NFF";
    }
    this.hardfunc.ChangeFirm(this.firmx);

    //Rekha Only display --Sprinkler ItemGroup
    if (this.hardfunc.UserName === "rekha.tc") {
      this.DataForm.controls['grp_nm'].patchValue(61);
    }
    if (this.hardfunc.UserName === "upasana") {
      this.DataForm.controls['State'].patchValue("FOREIGN");
    }

  }

  Prio: string;
  levels: Array<Object> = [
    { name: "Low", value: "L" },
    { name: "Medium", value: "M" },
    { name: "High", value: "H" }
  ];


  toNumber(Ordno: number, Oloc: number, Prio: number) {    
    this.pendordservice.SavePriority(Ordno, Oloc, Prio)
      .subscribe(
        (data: any) => {
          this.table.renderRows();
          //this.levels[0].toString[this.Prio].patchValue(data.Prio);
          this.notificationservice.success(' Priority Saved Successfully... ');
          this.loading = false;
        },
        err => {
          let errstr = this.hardfunc.getError(err);
          this.notificationservice.warn(errstr);
          this.loading = false;
        }
      );
  }
  allSelected = false;
  Category: any[] = [
    { value: 0, viewValue: 'WHITE' },
    { value: 1, viewValue: 'RED' },
    { value: 2, viewValue: 'BLUE' },
    { value: 3, viewValue: 'YELLOW' },
    { value: 4, viewValue: 'GREEN' },
    { value: 5, viewValue: 'PURPLE' },
    { value: 6, viewValue: 'SAFFRON' },
    { value: 7, viewValue: 'PINK' },
    { value: 8, viewValue: 'GREY' },
    { value: 9, viewValue: 'DARK PINK' },
    { value: 10, viewValue: 'DARK BLUE' },
  ];

  optionClick(cvalue: number) {
    this.OrderCate += cvalue + ",";
  }

  getOrdDetails(datalist: any) {
    datalist.map(m => {
      this.Ordnos = m.Ordno;
      this.Olocs = m.Oloc;
    });
    this.pendordservice.GetDatabyOrdforprio(this.hardfunc.Firmx, this.Ordnos, this.Olocs)
      .subscribe(
        (data: any) => {
          data.Status = data.Status.trim();
          data.Prio = data.Prio;
        }
      )
  }

  //Order Categories
  OrderCategories(Ordno: number, Oloc: number, Categories: number) {
    console.log(Ordno);
    console.log(Oloc);
    console.log(Categories);
    this.pendordservice.OrderCategory(Ordno, Oloc, Categories)
      .subscribe(
        (data: any) => {
          this.notificationservice.success('Changed Order Category Successfully... ');
          this.table.renderRows();
          this.loading = false;
        },
        err => {
          let errstr = this.hardfunc.getError(err);
          this.notificationservice.warn(errstr);
          this.loading = false;
        }
      )
  }

  //Sub Category 1
  OrderCat1(Ordno: number, Oloc: number, Cat1: number) {
    this.pendordservice.OrderCat1(Ordno, Oloc, Cat1)
      .subscribe(
        (data: any) => {
          this.Cat1.renderRows();
          this.notificationservice.success('Changed Sub Category Successfully... ');
          this.loading = false;
        },
        err => {
          let errstr = this.hardfunc.getError(err);
          this.notificationservice.warn(errstr);
          this.loading = false;
        }
      )
  }

  //Sub Category 2
  OrderCat2(Ordno: number, Oloc: number, Cat2: number) {
    this.pendordservice.OrderCat2(Ordno, Oloc, Cat2)
      .subscribe(
        (data: any) => {
          this.Cat2.renderRows();
          this.notificationservice.success('Changed Sub Category Successfully...  ');
          this.loading = false;
        },
        err => {
          let errstr = this.hardfunc.getError(err);
          this.notificationservice.warn(errstr);
          this.loading = false;
        }
      )
  }

  //Sub Category 3
  OrderCat3(Ordno: number, Oloc: number, Cat3: number) {
    this.pendordservice.OrderCat3(Ordno, Oloc, Cat3)
      .subscribe(
        (data: any) => {
          this.Cat3.renderRows();
          this.notificationservice.success('Changed Sub Category Successfully...  ');
          this.loading = false;
        },
        err => {
          let errstr = this.hardfunc.getError(err);
          this.notificationservice.warn(errstr);
          this.loading = false;
        }
      )
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }
  toggleShow() {
    this.isShown = !this.isShown;
  }

  DisplayCol() {
    let isSm = window.matchMedia(`(max-width: 960px)`).matches;
    this.displayedColumns = ['Ordno', 'Ordnos', 'Party_name', 'Product_detail', 'Deli_dts', 'Ins_yn', 'Bg_yn', 'Ld_yn', 'Payterms', 'Remarks', 'Action'];
  }

  // 3. api/SordersAPI/SOList1 -- API For -- Get Sales Order List
  getData(option: number) {
    this.loading = true;
    this.datalist = [];
    let url = this.hardfunc.BaseAPIUrl + "api/SordersAPI/SOList1";
    var headers = new HttpHeaders({
      'content-Type': 'application/json',
      'process-Data': 'false',
      'Authorization': 'Bearer ' + localStorage.getItem("UserToken")
    })

    this.repmodel.Firmx = this.hardfunc.Firmx;
    this.repmodel.Yrx = this.hardfunc.Yrx;
    var data1: any = {};
    let cnd = "  ";
    if (option === 1) // Normal Search
    {
      if (this.DataForm.value.Party_name.length > 0) {
        cnd += " and b.Party_name like ''%" + this.DataForm.value.Party_name + "%'' ";
      }
      if (this.DataForm.value.O_category != 'Select all') {
        if (this.DataForm.value.O_category >= [0]) {
          cnd += "and h.Color in (" + this.DataForm.value.O_category + ") ";
        }
      }
      if (this.repmodel.Priority != "*All*") {
        cnd += " and d.Prio = ''" + this.repmodel.Priority + "''";
      }
      if (this.DataForm.value.OrdDate) {
        cnd += " and a.OrdDt = convert(datetime,''" + moment(this.DataForm.value.OrdDate).format("DD/MM/YYYY") + "'',103)  ";
      }
      if (this.DataForm.value.Dname != "*All*") {
        cnd += " and d.dir_id = ''" + this.DataForm.value.Dname + "''";
      }
      if (this.DataForm.value.Mname != "*All*") {
        cnd += " and d.mkt_id = ''" + this.DataForm.value.Mname + "'' or d.fld_id = ''" + this.DataForm.value.Mname + "''";
      }
      //For Permission      
      if (this.DataForm.value.grp_nm != "*All*") {
        cnd += " and e.Prodgrp_id = " + this.DataForm.value.grp_nm;
      }
      if (this.DataForm.value.State == "Domestic") {
        cnd += " and b.Party_stat != ''FOREIGN'' "
      }
      else if (this.DataForm.value.State != "*All*") {
        cnd += " and b.Party_stat = ''" + this.DataForm.value.State + "''";
      }

      if (this.DataForm.value.Ord_Status != "*All*") {
        cnd += " and d.Status = ''" + this.DataForm.value.Ord_Status + "''";
      }
      if (this.DataForm.value.Insp != "*All*") {
        cnd += " and a.Ins_yn = ''" + this.DataForm.value.Insp + "'' ";
      }
      if (this.DataForm.value.Ordno > 0) {
        cnd += " and a.Ordno = " + this.DataForm.value.Ordno + " and a.Oloc = " + this.DataForm.value.Oloc;
      }
      if (this.Rights.Domestic === false && this.Rights.Foreign === true) {
        cnd += " and b.Party_stat = ''FOREIGN'' "
      }
      if (this.Rights.Domestic === true && this.Rights.Foreign === false) {
        cnd += " and b.Party_stat = ''Domestic'' "
      }
      if (this.Rights.Foreign === false) {
        cnd += " and b.Party_stat != ''FOREIGN'' "
      }
      if (this.Rights.CriGrp === false) // No rights to accesss critical groups like Fire Tender 
      {
        cnd += " and e.Prodgrp_id  not in (34,42,46,22,14) "
      }
    }
    this.repmodel.cond = cnd;
    if (this.Permission.substring(4, 5) === 'Y') {
      this.repmodel.Sp_permission = "S";
    }        
    this.repmodel.status = this.DataForm.value.Status;
    this._http.post(url, this.repmodel, { headers: headers })
      .subscribe((data1: any) => {           
        this.datalist = data1;        
        this.PqntyTotal = 0;
        this.datalist.map(d => {          
          d.SubDatas.map(s => {
            this.PqntyTotal += s.Pqnty;
          });
        });
        this.loading = false;
        this.dataSource = new MatTableDataSource(<any>this.datalist);
        this.getAttachment();
        this.getNotification();
        this.DisplayCol();
        this.getOrdDetails(this.datalist);
        this.onSort();  
        this.dataSource.paginator = this.paginator;
        this.DataForm.controls["Ordno"].patchValue(0);
        this.DataForm.controls["Oloc"].patchValue(0);
      },
        err => {
          console.log(err);
          // this.msgs.push({ severity: 'error', summary: 'Error', detail: `Error in getting data , please check server connectivity...  ` });
          this.openSnackBar("Error in getting data , please check server connectivity...  ", "dismiss");
          this.loading = false;
        });
  }
    
  // --Create PDF
  CreatePDF(option: number) {
    this.loading = true;
    this.datalist = [];
    let url = this.hardfunc.BaseAPIUrl + "api/SordersAPI/CreatePDFSOList1";
    var headers = new HttpHeaders({
      'content-Type': 'application/json',
      'process-Data': 'false',
      'Authorization': 'Bearer ' + localStorage.getItem("UserToken")
    })
    this.repmodel.Firmx = this.hardfunc.Firmx;
    this.repmodel.Yrx = this.hardfunc.Yrx;
    var data1: any = {};
    let cnd = "  ";
    let filename = "";
    if (option === 1) // Normal Search
    {
      if (this.DataForm.value.Party_name.length > 0) {
        cnd += " and b.Party_name like ''%" + this.DataForm.value.Party_name + "%'' ";
      }
      if (this.DataForm.value.O_category != 'Select all') {
        if (this.DataForm.value.O_category >= [0]) {
          cnd += "and h.Color in (" + this.DataForm.value.O_category + ") ";
        }
      }
      if (this.repmodel.Priority != "*All*") {
        cnd += " and d.Prio = ''" + this.repmodel.Priority + "''";
      }
      if (this.DataForm.value.OrdDate) {
        cnd += " and a.OrdDt = convert(datetime,''" + moment(this.DataForm.value.OrdDate).format("DD/MM/YYYY") + "'',103)  ";
      }
      if (this.DataForm.value.Dname != "*All*") {
        cnd += " and d.dir_id = ''" + this.DataForm.value.Dname + "''";
        this.DirectorList.map(w => {
          if (w.Handle_id == this.DataForm.value.Dname) {
            filename = w.Handle_nm.trim() + '_';
          }
        })
      }
      if (this.DataForm.value.Mname != "*All*") {
        cnd += " and d.mkt_id = ''" + this.DataForm.value.Mname + "''";
        this.MarketingList.map(w => {
          if (w.Handle_id == this.DataForm.value.Mname) {
            filename += w.Handle_nm.trim() + '_';
          }
        })
      }

      if (this.DataForm.value.grp_nm != "*All*") {
        cnd += " and e.Prodgrp_id = " + this.DataForm.value.grp_nm;
        this.PrdGroupList.map(w => {
          if (w.grp_id == this.DataForm.value.grp_nm) {
            filename += w.grp_nm.trim() + '_';
          }
        })
      }

      if (this.DataForm.value.State == "Domestic") {
        cnd += " and b.Party_stat != ''FOREIGN'' "
      }
      else if (this.DataForm.value.State != "*All*") {
        cnd += " and b.Party_stat = ''" + this.DataForm.value.State + "''";
      }

      if (this.DataForm.value.Ord_Status != "*All*") {
        cnd += " and d.Status = ''" + this.DataForm.value.Ord_Status + "''";
      }
      // if (this.DataForm.value.Prio != "*All*") {
      //   cnd += " and d.Prio = ''" + this.DataForm.value.Prio + "''";
      // }   
      if (this.DataForm.value.Insp != "*All*") {
        cnd += " and a.Ins_yn = ''" + this.DataForm.value.Insp + "'' ";
      }
      if (this.DataForm.value.Ordno > 0) {
        cnd += " and a.Ordno = " + this.DataForm.value.Ordno + " and a.Oloc = " + this.DataForm.value.Oloc;
      }
      if (this.Rights.Domestic === false && this.Rights.Foreign === true) {
        cnd += " and b.Party_stat = ''FOREIGN'' "
      }
      if (this.Rights.Domestic === true && this.Rights.Foreign === false) {
        cnd += " and b.Party_stat = ''Domestic'' "
      }
      if (this.Rights.Foreign === false) {
        cnd += " and b.Party_stat != ''FOREIGN'' "
      }
      if (this.Rights.CriGrp === false) // No rights to accesss critical groups like Fire Tender 
      {
        cnd += " and e.Prodgrp_id  not in (34,42,46,22,14) "
      }

    }
    this.repmodel.cond = cnd;
    this.repmodel.status = this.DataForm.value.Status;
    this._http.post(url, this.repmodel, { headers: headers, responseType: 'blob' as 'json' })
      .subscribe((data1: any) => {
        this.loading = false;
        console.log(data1);
        const data = window.URL.createObjectURL(data1);
        var link = document.createElement('a');
        link.href = data;
        link.target = "_blank";


        link.download = filename + "SALES ORDER.pdf";
        link.click();
        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(data);
        }, 100);
      },
        err => {
          console.log(err);
          // this.msgs.push({ severity: 'error', summary: 'Error', detail: `Error in getting data , please check server connectivity...  ` });
          this.openSnackBar("Error in getting data , please check server connectivity...  ", "dismiss");
          this.loading = false;
        });
  }

  DelidtPDF(option: number) {
    this.loading = true;
    this.datalist = [];
    let url = this.hardfunc.BaseAPIUrl + "api/SordersAPI/DelidtCreatePDF";
    var headers = new HttpHeaders({
      'content-Type': 'application/json',
      'process-Data': 'false',
      'Authorization': 'Bearer ' + localStorage.getItem("UserToken")
    })
    this.repmodel.Firmx = this.hardfunc.Firmx;
    this.repmodel.Yrx = this.hardfunc.Yrx;
    var data1: any = {};
    let cnd = "  ";
    let filename = "";
    if (option === 1) // Normal Search
    {
      if (this.DataForm.value.Party_name.length > 0) {
        cnd += " and b.Party_name like ''%" + this.DataForm.value.Party_name + "%'' ";
      }
      if (this.DataForm.value.O_category != 'Select all') {
        if (this.DataForm.value.O_category >= [0]) {
          cnd += "and h.Color in (" + this.DataForm.value.O_category + ") ";
        }
      }
      if (this.DataForm.value.OrdDate) {
        cnd += " and a.OrdDt = convert(datetime,''" + moment(this.DataForm.value.OrdDate).format("DD/MM/YYYY") + "'',103)  ";
      }
      if (this.DataForm.value.Dname != "*All*") {
        cnd += " and d.dir_id = ''" + this.DataForm.value.Dname + "''";
        this.DirectorList.map(w => {
          if (w.Handle_id == this.DataForm.value.Dname) {
            filename = w.Handle_nm.trim() + '_';
          }
        })
      }
      if (this.DataForm.value.Mname != "*All*") {
        cnd += " and d.mkt_id = ''" + this.DataForm.value.Mname + "''";
        this.MarketingList.map(w => {
          if (w.Handle_id == this.DataForm.value.Mname) {
            filename += w.Handle_nm.trim() + '_';
          }
        })
      }

      if (this.DataForm.value.grp_nm != "*All*") {
        cnd += " and e.Prodgrp_id = " + this.DataForm.value.grp_nm;
        this.PrdGroupList.map(w => {
          if (w.grp_id == this.DataForm.value.grp_nm) {
            filename += w.grp_nm.trim() + '_';
          }
        })
      }

      if (this.DataForm.value.State == "Domestic") {
        cnd += " and b.Party_stat != ''FOREIGN'' "
      }
      else if (this.DataForm.value.State != "*All*") {
        cnd += " and b.Party_stat = ''" + this.DataForm.value.State + "''";
      }

      if (this.DataForm.value.Ord_Status != "*All*") {
        cnd += " and d.Status = ''" + this.DataForm.value.Ord_Status + "''";
      }
      // if (this.DataForm.value.Prio != "*All*") {
      //   cnd += " and d.Prio = ''" + this.DataForm.value.Prio + "''";
      // }      
      if (this.DataForm.value.Ordno > 0) {
        cnd += " and a.Ordno = " + this.DataForm.value.Ordno + " and a.Oloc = " + this.DataForm.value.Oloc;
      }
      if (this.Rights.Domestic === false && this.Rights.Foreign === true) {
        cnd += " and b.Party_stat = ''FOREIGN'' "
      }
      if (this.Rights.Domestic === true && this.Rights.Foreign === false) {
        cnd += " and b.Party_stat = ''Domestic'' "
      }
      if (this.Rights.Foreign === false) {
        cnd += " and b.Party_stat != ''FOREIGN'' "
      }
      if (this.Rights.CriGrp === false) // No rights to accesss critical groups like Fire Tender 
      {
        cnd += " and e.Prodgrp_id  not in (34,42,46,22,14) "
      }

    }
    this.repmodel.cond = cnd;
    this.repmodel.status = this.DataForm.value.Status;
    this._http.post(url, this.repmodel, { headers: headers, responseType: 'blob' as 'json' })
      .subscribe((data1: any) => {
        this.loading = false;
        console.log(data1);
        const data = window.URL.createObjectURL(data1);
        var link = document.createElement('a');
        link.href = data;
        link.target = "_blank";


        link.download = filename + "SALES ORDER.pdf";
        link.click();
        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(data);
        }, 100);
      },
        err => {
          console.log(err);
          // this.msgs.push({ severity: 'error', summary: 'Error', detail: `Error in getting data , please check server connectivity...  ` });
          this.openSnackBar("Error in getting data , please check server connectivity...  ", "dismiss");
          this.loading = false;
        });
  }
  OpenOrd(Ordno: number, Oloc: number) {
    this.DataForm.controls["Ordno"].patchValue(Ordno);
    this.DataForm.controls["Oloc"].patchValue(Oloc);
    this.getData(1);
  }
  SorderPDF(option: number) {
    this.loading = true;
    this.datalist = [];
    let url = this.hardfunc.BaseAPIUrl + "api/SordersAPI/PDFDownSOList";
    var headers = new HttpHeaders({
      'content-Type': 'application/json',
      'process-Data': 'false',
      'Authorization': 'Bearer ' + localStorage.getItem("UserToken")
    })
    this.repmodel.Firmx = this.hardfunc.Firmx;
    this.repmodel.Yrx = this.hardfunc.Yrx;
    var data1: any = {};
    let cnd = "  ";

    if (option === 1) // Normal Search
    {
      if (this.DataForm.value.Party_name.length > 0) {
        cnd += " and b.Party_name like ''%" + this.DataForm.value.Party_name + "%'' ";
      }
      if (this.DataForm.value.O_category != 'Select all') {
        if (this.DataForm.value.O_category >= [0]) {
          cnd += "and h.Color in (" + this.DataForm.value.O_category + ") ";
        }
      }
      if (this.DataForm.value.OrdDate) {
        cnd += " and a.OrdDt = convert(datetime,''" + moment(this.DataForm.value.OrdDate).format("DD/MM/YYYY") + "'',103)  ";
      }
      if (this.DataForm.value.Dname != "*All*") {
        cnd += " and d.dir_id = ''" + this.DataForm.value.Dname + "''";
      }
      if (this.DataForm.value.Mname != "*All*") {
        cnd += " and d.mkt_id = ''" + this.DataForm.value.Mname + "''";
      }
      // if (this.DataForm.value.Product_id > 0) {
      //   cnd += " and a.Product_id  = " + this.DataForm.value.Product_id ;
      // }      
      if (this.DataForm.value.grp_nm != "*All*") {
        cnd += " and e.Prodgrp_id = " + this.DataForm.value.grp_nm;
      }

      if (this.DataForm.value.State == "Domestic") {
        cnd += " and b.Party_stat != ''FOREIGN'' "
      }
      else if (this.DataForm.value.State != "*All*") {
        cnd += " and b.Party_stat = ''" + this.DataForm.value.State + "''";
      }

      if (this.DataForm.value.Ord_Status != "*All*") {
        cnd += " and d.Status = ''" + this.DataForm.value.Ord_Status + "''";
      }
      if (this.DataForm.value.Prio != "*All*") {
        cnd += " and d.Prio = ''" + this.DataForm.value.Prio + "''";
      }
      if (this.DataForm.value.Ordno > 0) {
        cnd += " and a.Ordno = " + this.DataForm.value.Ordno + " and a.Oloc = " + this.DataForm.value.Oloc;
      }
      if (this.Rights.Domestic === false && this.Rights.Foreign === true) {
        cnd += " and b.Party_stat = ''FOREIGN'' "
      }
      if (this.Rights.Domestic === true && this.Rights.Foreign === false) {
        cnd += " and b.Party_stat = ''Domestic'' "
      }
      if (this.Rights.Foreign === false) {
        cnd += " and b.Party_stat != ''FOREIGN'' "
      }
      if (this.Rights.CriGrp === false) // No rights to accesss critical groups like Fire Tender 
      {
        cnd += " and e.Prodgrp_id  not in (34,42,46,22,14) "
      }

    }
    this.repmodel.cond = cnd;
    this.repmodel.status = this.DataForm.value.Status;
    this._http.post(url, this.repmodel, { headers: headers, responseType: 'blob' as 'json' })
      .subscribe((data1: any) => {
        console.log(data1);
        this.loading = false;
        console.log(data1);
        const data = window.URL.createObjectURL(data1);

        var link = document.createElement('a');
        link.href = data;
        link.target = "_blank";
        link.download = "Sorder.pdf";
        link.click();
        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(data);
        }, 100);
      },
        err => {
          console.log(err);
          // this.msgs.push({ severity: 'error', summary: 'Error', detail: `Error in getting data , please check server connectivity...  ` });
          this.openSnackBar("Error in getting data , please check server connectivity...  ", "dismiss");
          this.loading = false;
        });
  }




  // 4. api/Common/notificationssAPI/GetNotificationbyUserandType -- API For -- Get Notification By User & Type
  getNotification() {
    this.Noti_Array = [];
    this.adminservice.getNotificationbyType("OR")
      .subscribe(
        (data: any) => {
          this.Noti_Array = data;
          this.no_of_msg = 0;
          this.Noti_Array.map(m => {
            this.no_of_msg += 1;
            // if (m.Msg != null) {

            //   let x = m.Msg.split('#&#');
            //   m.MsgHeader = x[0];
            //   m.MsgBody = x[1];
            //   m.MsgFooter = x[2];
            // }
          });
        },
        err => {
          let errstr = this.hardfunc.getError(err);
          console.log(err);
          this.notificationservice.warn(errstr);
        }
      )
  }
  OpenBOM(Product_id: number, Product_nm: string, Qnty: number) {
    const dialogRef = this.dialog.open(BOMComponent, {
      width: '900px',
      height: '500px',
      data: { Product_id: Product_id, Product_nm: Product_nm, Qnty: Qnty }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  // 5. api/Common/notificationssAPI/DeleteData -- API For -- Delete Notification
  Delete_Noti(Ordno: number): void {
    this.loading = true;
    this.adminservice.Delete_notification(Ordno)
      .subscribe(
        (data: any) => {
          this.loading = false;
          this.getNotification();
        },
        err => {
          this.loading = false;
          let errstr = this.hardfunc.getError(err);
          console.log(err);
        }
      )
  }
  // 6. api/Common/CodemastsAPI/GetCodeListbyCodenm --API For --Order Status
  getOrd_Status() {
    this.loading = true;
    this.commonservice.CodeList('Ord_Status')
      .subscribe(
        (data: any) => {
          this.Ord_Status = JSON.parse(data);
          this.loading = false;
        },
        err => {
          this.loading = false;
          let errstr = this.hardfunc.getError(err);
          this.notificationservice.warn(errstr);
        }
      );
  }

  // 7. api/DMSAPI/DocuSumbyOrdno -- API For -- Document Sum by Order No.
  getAttachment() {
    this.pendordservice.GetDatabyAttach(this.hardfunc.Firmx, 'OR')
      .subscribe(
        (data: any) => {
          data.map(w => {
            if (w) {
              var obj: any = this.datalist.filter((x: any) => x.Ordno === w.Ordno && x.Oloc === w.Oloc)[0];
              if (obj) {
                obj.No_of_docu = w.No_of_docu;
                obj.No_of_chats = w.No_of_chats;
                obj.No_of_corrs = w.No_of_corrs;
              }
            }
          });
        }
      );
  }
  createFormGroup() {
    return this.fb.group({
      Party_name: new FormControl(''),
      OrdDate: new FormControl(null),
      fdate: new FormControl(''),
      tdate: new FormControl(''),
      grp_nm: new FormControl('*All*'),
      Dname: new FormControl('*All*'),
      Mname: new FormControl('*All*'),
      State: new FormControl('Domestic'),
      Status: new FormControl('P'),
      Remarks: new FormControl(''),
      Ord_Status: new FormControl('*All*'),
      Status_change: new FormControl('*All*'),
      //Prio: new FormControl('*All*'),
      Ordno: new FormControl(0),
      Oloc: new FormControl(0),
      Product_id: new FormControl(0),
      Insp: new FormControl('*All*'),
      O_category: new FormControl()
    });
  }

  NotificationForm() {

  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  // 8. api/HandlesAPI/GetData -- API For -- Get Directore Type=D
  getHandles() {
    let url = this.hardfunc.BaseAPIUrl + "api/HandlesAPI/GetData?Type=D";
    this._http.get(url, this.repmodel)
      .subscribe((data1: any) => {
        this.DirectorList = data1;
      }, err => {
        console.log(err);
      });
  }

  // 8. api/HandlesAPI/GetData -- API For -- Get Marketing Type=M
  getMarketing() {
    let url = this.hardfunc.BaseAPIUrl + "api/HandlesAPI/GetData?Type=M','F";
    this._http.get(url, this.repmodel)
      .subscribe((data1: any) => {
        this.MarketingList = data1;
      }, err => {
        console.log(err);
      });
  }

  // 9. api/Common/StateMastsAPI/GetDatas API For --Get State Master
  getState() {
    let url = this.hardfunc.BaseAPIUrl + "api/Common/StateMastsAPI/GetDatas";
    this._http.post(url, this.repmodel)
      .subscribe((data1: any) => {
        this.StateList = data1;
      }, err => {
        console.log(err);
      });
  }

  openDialog(Ordno: number, Oloc: number, Notes_type: string): void {

    let width = '1000px';
    let height = '600px';
    if (Notes_type === 'OR') {
      width = '940px';
      height = '545px';
    }
    const dialogRef = this.dialog.open(PendordDialogComponent, {
      width: width,
      height: height,
      data: { Ordno: Ordno, Oloc: Oloc, Notes_type: Notes_type }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      console.log('The dialog was closed');
    });
  }

  openAttachment(Ordno: number, Oloc: number, Vtype: string): void {
    const dialogRef = this.dialog.open(PendordAttachComponent, {
      width: '925px',
      height: '600px',
      data: { Ordno: Ordno, Oloc: Oloc, Vtype: Vtype, Permission: this.Permission, Wmodule_id: 1125 }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
  Osdetail(Yrx: Number, Pmst_cod: string, Party_name: string, Party_city: string, Party_stat: string, Lyr_clbal: number) {

    let Firm = this.hardfunc.Firmx;
    const dialogRef = this.dialog.open(OsdetailComponent, {

      width: '1000px',
      data: { Firm, Yrx, PMST_COD: Pmst_cod, Party_name: Party_name, Party_city: Party_city, Party_stat: Party_stat, Party_bal: Lyr_clbal }

    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });

  }
  Grpbalance(Pmst_cod: string, Party_name: string, Party_city: string, Party_Stat: string) {
    const dialogRef = this.dialog.open(GrpBalanceComponent, {
      width: '925px',
      height: '600px',
      data: { Pmst_cod: Pmst_cod, Party_name: Party_name, Party_city: Party_city, Party_Stat: Party_Stat }
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  sortData(sort: Sort) {
    const data = this.datalist.slice();
    if (!sort.active || sort.direction === '') {
      this.dataSource = data;
      return;
    }

    this.dataSource = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'Ordno': return compare(a.Ordno, b.Ordno, isAsc);
        case 'Party_name': return compare(a.Party_name, b.Party_name, isAsc);
        case 'Deli_dts': return compare(a.Deli_dts, b.Deli_dts, isAsc);
        default: return 0;
      }
    });
  }

  GetInv_by_Ordno(ordno: number, oloc: number) {
    let mydata: any = {};
    this.repmodel = {};
    this.repmodel.ordno = ordno;
    this.repmodel.oloc = oloc;

    const dialogRef = this.dialog.open(OrderInvComponent, {
      width: '250px',
      data: this.repmodel
    });
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  onFilter(event) {
    this.updateRowGroupMetaDataFilter(event.filteredValue);
  }

  onSort() {
    this.rowGroupMetadata = {};
    if (this.datalist) {
      for (let i = 0; i < this.datalist.length; i++) {
        let rowData = this.datalist[i];
        let ordno = rowData.ordno;
        if (i == 0) {
          this.rowGroupMetadata[ordno] = { index: 0, size: 1 };
        }
        else {
          let previousRowData = this.datalist[i - 1];
          let previousRowGroup = previousRowData.ordno;
          if (ordno === previousRowGroup)
            this.rowGroupMetadata[ordno].size++;
          else
            this.rowGroupMetadata[ordno] = { index: i, size: 1 };
        }
      }
    }
  }

  updateRowGroupMetaDataFilter(data) {
    this.rowGroupMetadata = {};
    if (data) {
      for (let i = 0; i < data.length; i++) {
        let rowData = data[i];
        let ordno = rowData.ordno;
        if (i == 0) {
          this.rowGroupMetadata[ordno] = { index: 0, size: 1 };
        }
        else {
          let previousRowData = data[i - 1];
          let last_order = previousRowData.ordno;
          if (ordno === last_order)
            this.rowGroupMetadata[ordno].size++;
          else
            this.rowGroupMetadata[ordno] = { index: i, size: 1 };
        }
      }
    }
    console.log(this.rowGroupMetadata);
  }

  ngOnDestroy(): void {
    console.clear();
  }

  // Order Priority
  onRightClick(data) {
    data.isClicked = true;
    return false;
  }
  //Order Category
  ClickRightCate(data) {
    data.CateClicked = true;
    return false;
  }
  CloseDropDown(data) {
    data.CateClicked = false;
    return false;
  }



  //Sub Category 2
  RightClickCat1(data) {
    data.isClickedCat1 = true;
    return false;
  }
  ClDrpDownCat1(data) {
    data.isClickedCat1 = false;
    return false;
  }

  //Sub Category 2
  RightClickCat2(data) {
    data.isClickedCat2 = true;
    return false;
  }
  ClDrpDownCat2(data) {
    data.isClickedCat2 = false;
    return false;
  }

  //Sub Category 3
  RightClickCat3(data) {
    data.isClickedCat3 = true;
    return false;
  }
  ClDrpDownCat3(data) {
    data.isClickedCat3 = false;
    return false;
  }

  //Order Status
  onRightClickOrdStatus(data) {

    data.isClickedOrdStatus = true;
    return false;
  }
  CloseOrdStatusDrp(data) {
    data.isClickedOrdStatus = false;
    return false;
  }
  exportexcel():void{
		// Option-2 Entire JSON
		const ws1:XLSX.WorkSheet=XLSX.utils.json_to_sheet(this.datalist);

		// Option-3 Selected fields from JSON
		let newData = this.datalist.map(({Ordno, Pmst_cod, Party_name, Party_city, Party_stat, ItemsTotal}) =>
										({Ordno, Pmst_cod, Party_name, Party_city, Party_stat, ItemsTotal}));
		const ws:XLSX.WorkSheet=XLSX.utils.json_to_sheet(newData);
		
		const wb:XLSX.WorkBook=XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws,'Sheet1');
	
		XLSX.writeFile(wb,this.filename);
	  }
  UpdOrder_status(Ordno: number, Oloc: number, drow: any) {
    let data: any = {};
    data.Ordno = Ordno;
    data.Oloc = Oloc;
    data.Status = drow.StatusCode;
    data.Option = "Status";
    data.Firm = this.hardfunc.Firmx;
    this.pendordservice.UpdateData(data)
      .subscribe(
        (data: any) => {
          this.Ord_Status.map(w => {
            if (w.Cd === drow.StatusCode) {
              drow.Status = w.Vals;
            }
          });
          //this.table2.renderRows();
          this.notificationservice.success(' Data Saved Successfully... ');
          this.loading = false;
        },
        err => {
          let errstr = this.hardfunc.getError(err);
          this.notificationservice.warn(errstr);
          this.loading = false;
        }
      )
  }

  //Depricated...
  GetDocu(Ordno: number, Oloc: number, DocuType: string) {
    let url = this.hardfunc.BaseAPIUrl + "api/Common/DocuViewerAPI/GetDocu";
    var r1: any = {};
    r1.Ordno = Ordno;
    r1.Oloc = Oloc;
    r1.Firmx = this.hardfunc.Firmx;
    r1.Yrx = this.hardfunc.Yrx;
    r1.DocuType = DocuType;
    var headers = new HttpHeaders({
      'content-Type': 'application/json',
      'process-Data': 'false',
      'Accept': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem("UserToken")
    })

    this._http.post(url, r1, { headers: headers, responseType: 'blob' as 'json' })
      .subscribe((data1: any) => {
        const data = window.URL.createObjectURL(data1);

        var link = document.createElement('a');
        link.href = data;
        link.target = "_blank";
        link.download = "file.pdf";
        link.click();
        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(data);
        }, 100);
        this.loading = false;

      },
        err => {
          console.log(err);
          // this.msgs.push({ severity: 'error', summary: 'Error', detail: `Error in getting data , please check server connectivity...  ` });
          this.openSnackBar("Error in getting data , please check server connectivity...  ", "dismiss");
          this.loading = false;
        });

  }

}
function compare(a: number | string | Date, b: number | string | Date, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
