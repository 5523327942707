import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'; 
import { Globals } from './Global';
import { CommonService } from './Services/Common/common.service';
import { NgxPaginationModule} from 'ngx-pagination'; 
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { DashboardComponent } from './Views/dashboard/dashboard.component';
import { DateAdapter, MAT_DATE_FORMATS} from '@angular/material/core';
import { MatInputModule, MatIconModule, MatCardModule, MatMenuModule, MatCheckboxModule, MatProgressBarModule, MatButtonModule, MatChipsModule, MatListModule, MatGridListModule, MatSidenavModule, MatTooltipModule, MatSelectModule, MatToolbarModule, MatSnackBarModule, MAT_DATE_LOCALE, MatRadioButton, MatRadioModule, MatBadgeModule, MatTableModule, MatPaginatorModule, MatDialogModule } from '@angular/material';
import { AppCommonModule } from './Components/common/app-common.module';
import { AuthGuard } from './Services/auth/auth.guard';
import { AuthInterceptor } from './Services/auth/auth.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; 
import { HardFuncService } from './Services/Common/hardfunc.service';
import { GeneralModule } from './Views/general/general.module';
import { AdminModule } from './Views/admin/admin.module';
import { MismatchDirective } from './Views/admin/usrreg/mismatch.directive';
import { PurchaseModule } from './Views/purchase/purchase.module';
import { ManufacturingModule } from './Views/manufacturing/manufacturing.module';
import { LOCALE_ID } from '@angular/core';
import { from } from 'rxjs';
import { DbdetailComponent } from './Views/dbdetail/dbdetail.component';
import { DatePipe } from '@angular/common';
import { WrapperDialogComponent } from './Views/shared/wrapper-dialog/wrapper-dialog.component';
import { AppDateAdapter, MY_DATE_FORMATS } from './Views/shared/mydateformat';
import { OsdetailComponent } from './Views/sales/osdetail/osdetail.component';
import { SalesModule } from './Views/sales/sales.module';
import { ProductHelpComponent } from './Views/shared/product-help/product-help.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { PartyListComponent } from './Views/shared/party/party';
import { PartyHelpComponent } from './Views/shared/party-help/party-help.component';
import { EmailComponent } from './Views/shared/email/email.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { RatehelpComponent } from './Views/shared/ratehelp/ratehelp.component';
import { JwHistoryComponent } from './Views/shared/jw-history/jw-history.component';
import { JwSummaryComponent } from './Views/shared/jw-summary/jw-summary.component';
import { JwSummaryItemComponent } from './Views/shared/jw-summary-item/jw-summary-item.component';
import { TproductComponent } from './Views/shared/tproduct/tproduct.component';
import { TempmastComponent } from './Views/shared/tempmast/tempmast.component';
import { TprodAdditemComponent } from './Views/shared/tprod-additem/tprod-additem.component';
import { GunmtlScrapitemComponent } from './Views/shared/gunmtl-scrapitem/gunmtl-scrapitem.component';
import { GrpBalanceComponent } from './Views/shared/grp-balance/grp-balance.component';
import { RcPartyHelpComponent } from './Views/shared/rc-party-help/rc-party-help.component';
import { PieChartComponent } from './Views/shared/pie-chart/pie-chart.component';
// import { NgApexchartsModule } from 'ng-apexcharts';


const Material = [
    MatInputModule,
    MatIconModule,
    MatCardModule,
    MatMenuModule,
    MatProgressBarModule,
    MatButtonModule,
    MatChipsModule,
    MatListModule,
    MatSidenavModule,
    MatGridListModule,
    MatTooltipModule,
    MatToolbarModule,
    MatSelectModule,
    MatSnackBarModule,
    MatRadioModule,
    MatBadgeModule,
    MatAutocompleteModule,
    MatTableModule,
    MatCheckboxModule,
    MatDialogModule

]

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    MismatchDirective,
    DbdetailComponent,
    WrapperDialogComponent,
    ProductHelpComponent,
    PartyListComponent,
    PartyHelpComponent,
    EmailComponent,
    RatehelpComponent,
    JwHistoryComponent,
    JwSummaryComponent,
    JwSummaryItemComponent,
    TproductComponent,
    TempmastComponent,
    TprodAdditemComponent,
    GunmtlScrapitemComponent,
    GrpBalanceComponent,
    RcPartyHelpComponent,
    PieChartComponent,
  ],

  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxPaginationModule,
    Ng2SearchPipeModule,
    MatFormFieldModule,
    CKEditorModule,
    // NgApexchartsModule,    
    Material,
    AppCommonModule,
    GeneralModule,
    AdminModule,
    PurchaseModule,
    ManufacturingModule,
    SalesModule,
    MatPaginatorModule
  ],
  providers: [
    Globals,
    CommonService,
    HardFuncService,
    //RoutePartsService
    //NavigationService,
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    DatePipe,
    {provide: MAT_DATE_LOCALE, useValue: 'en-IN'},
//    { provide: DateAdapter, useClass: AppDateAdapter },
  ],
  bootstrap: [AppComponent],
  entryComponents:[
    DbdetailComponent,
    OsdetailComponent,
    WrapperDialogComponent,
    ProductHelpComponent,
    PartyListComponent,
    PartyHelpComponent,
    EmailComponent,
    RatehelpComponent,
    JwHistoryComponent,
    JwSummaryComponent,
    JwSummaryItemComponent,
    TproductComponent,
    TempmastComponent,
    TprodAdditemComponent,
    GunmtlScrapitemComponent,
    GrpBalanceComponent,
    RcPartyHelpComponent,
    PieChartComponent
  ],
  exports:[

  ]
})
export class AppModule { }
