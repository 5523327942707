import { Component, OnInit, Inject, Optional, OnDestroy } from '@angular/core';
import { CommonService } from 'src/app/Services/Common/common.service';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { FormGroup, FormControl, Validators, RequiredValidator, FormBuilder, FormArray } from '@angular/forms';
import { PdcListComponent } from '../pdc/Pdc-list.component';
import { MatSnackBar, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { NotificationService } from 'src/app/Services/Common/notification.service';
import { PdcService } from './Pdc.service';
import { AdminService } from 'src/app/Views/admin/admin.service';

@Component({
  selector: 'app-Pdc-crud',
  templateUrl: './Pdc-crud.component.html',
  styleUrls: ['./Pdc-crud.component.css']
})

export class PdcCrudComponent implements OnInit, OnDestroy {
  title: string;
  DataForm: FormGroup;
  loading: boolean = false;
  ploading: boolean = false;
  msgs: any = [];
  PartyData: any = [];
  StatusList: any = [];
  filteredOptions: any = [];
  datemask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
  //invmask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
  CustodyList: any = [];
  Permission:string = "NNNNNNNNN";
  pdctplist: any = [];
  initialValues : any [];

  constructor(
              private hardfunc: HardFuncService,
              private commonservice: CommonService,
              private notificationService: NotificationService,
              @Optional() public dialogRef: MatDialogRef<PdcListComponent>,
              private pdcservice: PdcService,
              private fb: FormBuilder,
              private _snackBar: MatSnackBar,
              @Optional() @Inject(MAT_DIALOG_DATA) public ReceivedData: any,
              private adminservice: AdminService
            ) { }

  ngOnInit() {
    this.Permission = this.hardfunc.GetLocalPermissionbyModule_id(1040);
    this.DataForm = this.createFormGroup();
    this.initialValues = this.DataForm.value;
    this.loading = true;

    this.DataForm.get('Party_name').valueChanges.subscribe(response => {
      this._filter(response);
    });


    this.DataForm.get('Firm').valueChanges.subscribe(response => {

    });
    this.getCustodyList();
    this.getCodeList();
    this.getPdctpList();
    this.GetParty(this.hardfunc.Firmx, this.hardfunc.Yrx);
    this.GetData();
    //this.DataForm.reset()
    
  }
  ngOnDestroy(): void {
    console.clear();
  }
  //1. api/Common/CodemastsAPI/GetCodeListbyCodenm --Get PDC State
  getCodeList() {
    this.loading = true;
    this.commonservice.CodeList('PDC_STAT')
      .subscribe(
        (data: any) => {
          this.StatusList = JSON.parse(data);
          this.loading = false;
        },
        err => {
          this.loading = false;
          let errstr = this.hardfunc.getError(err);
          this.notificationService.warn(errstr);
        }

      );
  }
  //2. api/Common/CodemastsAPI/GetCodeListbyCodenm --Get PDC Custody
  getCustodyList() {
    this.loading = true;
    this.commonservice.CodeList('PDC_CUST')
      .subscribe(
        (data: any) => {
          this.CustodyList = JSON.parse(data);
          this.loading = false;
        },
        err => {
          this.loading = false;
          let errstr = this.hardfunc.getError(err);
          this.notificationService.warn(errstr);
        }
      );
  }

  //3. api/Common/CodemastsAPI/GetCodeListbyCodenm --Get PDC Type
  getPdctpList() {
    this.loading = true;
    this.commonservice.CodeList('PDCTP')
      .subscribe(
        (data: any) => {
          this.pdctplist = JSON.parse(data);
          this.loading = false;
        },
        err => {
          this.loading = false;
          let errstr = this.hardfunc.getError(err);
          this.notificationService.warn(errstr);
        }
      );
  }

  //4. api/FinanceAPI/GetPartyList --Get party List
  GetParty(Firm: string, Yr: string) {
    this.ploading = true;
    this.commonservice.GetPartyList(Firm, Yr)
      .subscribe(
        (data: any) => {
          this.PartyData = JSON.parse(data);
          this.PartyData = this.PartyData.map((x) => {
            return {
              nm: x.id.trim()+'-'+x.nm.trim() +'-'+ x.Party_city.trim() + '-'+ x.Party_Stat.trim(),
              id: x.id
            }
          });
          //this.PartyData.map((res:[]) => res.map(item => item["PARTY_NAME"]) )
          //this.filteredOptions = this.PartyData;
          this.ploading = false;
        },
        err => {
          let errstr = this.hardfunc.getError(err);
          this.notificationService.warn(errstr);
          this.ploading = false;
        });
  }

//5. api/PdcAPI/GetDatabyId --Save and Edit PDC Data
  GetData() {
    if (this.ReceivedData) {
      
      if (this.ReceivedData.id == -1) {
        this.title = "ADDITION";
      }
      else {
        this.pdcservice.GetDatabyId(this.ReceivedData.id)
          .subscribe(
            (data: any) => {
              data.Status = data.Status.trim();
              data.Firm = data.Firm.trim();
              this.DataForm.patchValue(data);
              const formarr = new FormArray([]);
              data.InvData.map(m => {
                formarr.push(
                  this.fb.group({
                    Invyr: m.Invyr,
                    Exr: m.Exr,
                    Invno: m.Invno,
                    Pk_id: m.Pk_id,
                    Pdc_pk_id: m.Pdc_pk_id
                  })
                );
              });
              this.DataForm.setControl("InvData", formarr);
              this.title = "EDITING";
              return false;
            },
            err => {
              let errstr = this.hardfunc.getError(err);
              this.notificationService.warn(errstr);
            }
          )
      }
    }
    else {
      this.ReceivedData = {};
      this.ReceivedData.id = -1;
      this.title = "ADDITION";
    }
  }  
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

  //6 api/PdcAPI/SaveData --Save PDC
  SaveData() {
    this.loading = true;
    this.pdcservice.SaveData(this.DataForm.value)
      .subscribe(
        data => {
          this.loading = false;
          this.onClose();
          //this.ResetData();
          this.notificationService.success(' Data Saved Successfully... ');
        },
        err => {
          let errstr = this.hardfunc.getError(err);
          console.log(err);
          this.notificationService.warn(errstr);
          this.loading = false;
        }
      )
  }
  ResetData() {
    if (this.ReceivedData.id > 0) {
      this.dialogRef.close();
    }
    else {
      this.DataForm.reset();
      this.DataForm.patchValue(this.initialValues);
      Object.keys(this.DataForm.controls).forEach(key => {
        this.DataForm.get(key).setErrors(null);
      });
    }
  }

  createFormGroup() {
    return this.fb.group({
      Firm: new FormControl('NFF'),
      Date: new FormControl(new Date(), Validators.required),
      Pdctp: new FormControl('*PDC*', Validators.required),
      Party_name: new FormControl('', Validators.required),
      //Acno: new FormControl(''),
      //Actype: new FormControl(''),
      //IFS_Code: new FormControl('', Validators.maxLength(11)),
      Bank: new FormControl(''),
      //Branch: new FormControl(''),
      Chqno: new FormControl('', Validators.required),
      Chqdt: new FormControl(new Date()),
      Amt: new FormControl('', Validators.required),
      Chq_depo_dt: new FormControl(
        {
          value: new Date()
        }),
      Custody: new FormControl('', Validators.required),
      Status: new FormControl('O', Validators.required),
      Comment: new FormControl(''),
      Pk_id: new FormControl(0),
      Pmst_cod: new FormControl('', Validators.maxLength(5)),
      Ordno: new FormControl(''),
      InvData: new FormArray([])
    });
  }
  

  initializeFormGroup() {

  }

  DataUpdate() {
    if (this.ReceivedData.id == -1) {
      let Chq_depo_dt = this.DataForm.value.Chqdt;
      this.DataForm.patchValue({ Chq_depo_dt: Chq_depo_dt });
      this.DataForm.patchValue({ Chqdt: Chq_depo_dt });
    }
  }
  
  AddSubData() {
    this.InvDatas.push(this.InvFormGroup());
  }

  removeSubData(i: number) {
    this.InvDatas.removeAt(i);
  }

  get InvDatas(): FormArray {
    return this.DataForm.get("InvData") as FormArray
  }

  InvFormGroup() {
    return this.fb.group({
      Pk_id: new FormControl(-1),
      Pdc_pk_id: new FormControl(-1),
      Invyr: new FormControl(this.hardfunc.Yrx),
      Exr: new FormControl('G'),
      Invno: new FormControl('', Validators.required),
      Invdt: new FormControl(new Date()),
      Invtot: new FormControl(0),
      Payterms: new FormControl(''),
      Duedt: new FormControl(new Date())
    });
  }

  onClear(){

  }

  onClose() {
    this.initializeFormGroup();
    if (this.ReceivedData.id > 0) {
      this.dialogRef.close();
    }
    else {
      this.DataForm.reset();
      Object.keys(this.DataForm.controls).forEach(key => {
        this.DataForm.get(key).setErrors(null);
      });
      Object.keys(this.InvDatas.controls).forEach(key => {
        this.InvDatas.get(key).setErrors(null);
      });
    }
  }

  private _filter(value: string) {
    if (!value)
    {
      this.filteredOptions = [];
      return;
    }
    if (value.length < 3) {
      this.filteredOptions = [];
      return;
    }
    const filterValue = value.toLowerCase();
    this.filteredOptions = this.PartyData.filter(option => option.nm.toLowerCase().includes(filterValue));
  }
}