import { Component, OnInit } from '@angular/core';
import { PurchaseService } from '../purchase.service';
import { MatSnackBar } from '@angular/material';
import { FormGroup, FormArray, FormControl, FormBuilder, Validators } from '@angular/forms';
import { HardFuncService } from 'src/app/Services/Common/hardfunc.service';
import { HttpClient } from '@angular/common/http';
import { ManufacturingService } from '../../manufacturing/manufacturing.services';
import { NotificationService } from 'src/app/Services/Common/notification.service';

@Component({
  selector: 'app-indent-reg',
  templateUrl: './indent-reg.component.html',
  styleUrls: ['./indent-reg.component.css']
})
export class IndentRegComponent implements OnInit {

  loading: boolean = true;
  Indenters: any = [];
  DataForm: FormGroup;
  repmodel: any = {};
  Productlist: any = [];
  
  constructor(private PurchaseService: PurchaseService,
              private MatSnackBar: MatSnackBar,
              private fb: FormBuilder,
              private hardfunc: HardFuncService,
              private _http: HttpClient,
              private ManufacService: ManufacturingService,
              private NotificationService: NotificationService
  ) { }

  ngOnInit() {
    this.GetIndeter();
    this.DataForm = this.CreateFormGroup();
  }
  SaveData(){  
    
    if(this.DataForm.get("Unit2").value == "*All*"){
      this.NotificationService.warn("Please select Department... ");
      return;
    }    
    if(this.DataForm.get("Indenter").value == "*All*"){
      this.NotificationService.warn("Please select Indenter... ");
      return;
    } 
    let formarray = (this.DataForm.controls['SubDatas'] as FormArray);    
    let srno: number = 1;
    formarray.value.map(d => {
      d.Srno = srno
      d.Firm = this.DataForm.get("Firm").value;
      d.Unit2 = this.DataForm.get("Unit2").value;
      d.Indt_id = this.DataForm.get("Indenter").value;
      srno++;
    })
    console.log(formarray.value);
    this.ManufacService.NewIndent(formarray.value)
      .subscribe((data: any) => {
        console.log(data);
        this.ResetData();
        this.NotificationService.success("Indent Saved Successfully... ");
        this.loading = false;
      },
      err => {
        let errstr = this.hardfunc.getError(err);
        this.NotificationService.warn(errstr);
        this.loading = false;
      });
  }
  addItems() {
    (this.DataForm.controls['SubDatas'] as FormArray).push(this.CreateSubData());
  }
  DeleteRow(i: number) {    
      (this.DataForm.controls['SubDatas'] as FormArray).removeAt(i);  
      // this.PurchaseService.IndentDelete(0)        
      //   .subscribe((data: any) => {
      //     console.log(data);
      //   });
  }
  GetIndeter() {
    this.loading = true;
    this.Indenters = [];
    
    this.PurchaseService.getIndenterList()
      .subscribe(
        (data: any) => {          
          this.Indenters = data;                 
          this.loading = false;
        },
        err => {
          this.loading = false;
          if (err.error.error_description) {
            this.openSnackBar(err.error.error_description, "dismiss");
          }
          else {
            this.openSnackBar("Error in getting data , please check server connectivity...  ", "dismiss");
          }
        }
      )
  }
  GetIndent(){
    this.PurchaseService.GetIndent(this.DataForm.get("MpiNo").value)
      .subscribe((data: any) => {
        console.log(data);
        data.SubDatas = [];          
          this.DataForm.get("Firm").patchValue(data.Firm);
          const formarr = new FormArray([]);
          data.map(m => {
            console.log(m.Reqdt);
            // console.log(m.Reqdt);
            // let Reqres = new Date();
            // if(m.Reqdt){
            //   let Reqdt = m.Reqdt;
            //   Reqres = new Date(Reqdt);
            // }else{
            //   Reqres = new Date();
            // }
            // console.log(Reqres);
            formarr.push(
              this.fb.group({
                Indent_id: m.Indent_id,
                Firm: m.Firm,
                Tdate: m.Tdate,
                Product_id: m.Product_id,
                Descri: m.Short_nm,
                Qnty1: m.Qnty1,
                Unit1: m.Unit1,
                Purpose: m.Purpose,
                Reqdt: m.Reqdt,
                Remark: m.Remark,    
                Slip_no: m.Slip_no,
                Type: 'E'            
              }),
            );
            let str = m.Tdate;
            let res: Date = new Date(str);            
            console.log(res); 
            this.DataForm.get("Firm").patchValue(m.Firm);
            this.DataForm.get("Indenter").patchValue(m.Indt_id);
            this.DataForm.get("Firm").patchValue(m.Firm);
            this.DataForm.get("Unit2").patchValue(m.Unit2);
            this.DataForm.get("Tdate").patchValue(this.formatDate(new Date(res)));
            this.DataForm.setControl("SubDatas", formarr);
            this.loading = false;
          });
        });                
  }
  GetProductlist(index: number) {
    let formarray = (this.DataForm.controls['SubDatas'] as FormArray);
    let formgroup = (formarray.at(index) as FormGroup);
    let product_id = formgroup.get('Product_id').value;
    let url = this.hardfunc.BaseAPIUrl + "api/ProductsAPI/GetProductDetail?product_id=" + product_id;
    this._http.get(url, this.repmodel)
      .subscribe((data1: any) => {        
        this.Productlist = data1;
        this.Productlist = this.Productlist.map((x) => {
          let formarray = (this.DataForm.controls['SubDatas'] as FormArray);
          let formgroup = (formarray.at(index) as FormGroup);
          formgroup.get('Descri').patchValue(x.Short_nm.trim());
          formgroup.get('Unit1').patchValue(x.Sunit.trim());
        });
      }, err => {
        console.log(err);
      });
  }
  ResetData() {      
      this.DataForm.reset();
  }
  CreateFormGroup() {
    return this.fb.group({
      // Slip_no: new FormControl(),
      MpiNo: new FormControl(),
      Firm: new FormControl('NFF'),
      Tdate: new FormControl(new Date()),
      Unit2: new FormControl('*All*'),
      Indenter: new FormControl('*All*'),
      SubDatas: this.fb.array([this.CreateSubData()]),
    });
  }
  CreateSubData(): FormGroup {
    return this.fb.group({
      // Slip_no: new FormControl(), 
      Indent_id: new FormControl(-1), 
      MpiNo: new FormControl(),
      Firm: new FormControl(''),
      Tdate: new FormControl(new Date()),
      Unit2: new FormControl(''),
      Indenter: new FormControl(''),

      Srno: new FormControl(),
      Product_id: new FormControl(),
      Descri: new FormControl(''),
      Qnty1: new FormControl(),
      Unit1: new FormControl(''),
      Purpose: new FormControl(''),
      Reqdt: new FormControl(new Date()),
      Remark: new FormControl(''),
      Type: new FormControl('N'),
    });
  }
  openSnackBar(message: string, action: string) {
    this.MatSnackBar.open(message, action, {
      duration: 2000,
    });
  }
  private formatDate(date) {        
    const d = new Date(date);    
    let month = '' + (d.getMonth() + 1);    
    let day = '' + d.getDate();
    const year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    console.log(year, month, day);
    return [year, month, day].join('-');
  }
}
